/* Variables */
/* Components */
/* Layout */
html {
  overflow-y: scroll; }

body {
  color: #222;
  height: auto;
  font-family: "proxima-nova", Arial, sans-serif;
  font-weight: 300; }

.page-main {
  width: 96%;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto; }

ul, ol, table {
  font-family: "proxima-nova", Arial, sans-serif; }

/* Text */
strong {
  font-weight: 600; }

/* Breadcrumbs */
.page-wrapper > .breadcrumbs {
  width: 96%;
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0; }

.breadcrumbs .items {
  color: #afafaf;
  font-size: 13px; }

.breadcrumbs .items a {
  color: #afafaf; }

/* Form Fields */
select,
input[type="text"], input[type="password"], input[type="url"],
input[type="tel"], input[type="search"], input[type="number"],
input[type="datetime"], input[type="email"] {
  font-family: "proxima-nova", Arial, sans-serif; }

/* Form Fields Focus */
select:focus,
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus {
  box-shadow: none !important; }

/* Labels */
.fieldset > .field > .label,
.fieldset > .fields > .field > .label {
  font-weight: 400; }

/* Links */
a,
a:visited,
a:active {
  color: #222; }

a:hover {
  color: #e63329; }

/* Page Titles */
.page-title-wrapper .page-title {
  color: #46804b;
  font-size: 28px;
  margin-bottom: 20px; }

/* Buttons */
.action.primary, #discount-form .action, .block-authentication .actions-toolbar > .primary .action {
  background: #46804b;
  color: #fff;
  border-color: #46804b;
  font-weight: 400;
  font-family: "proxima-nova", Arial, sans-serif;
  border-radius: 3px; }

.action.primary:focus, #discount-form .action:focus, .block-authentication .actions-toolbar > .primary .action:focus,
.action.primary:hover,
#discount-form .action:hover,
.block-authentication .actions-toolbar > .primary .action:hover,
.action.primary:active,
#discount-form .action:active,
.block-authentication .actions-toolbar > .primary .action:active {
  background: #3d7041;
  border-color: #3d7041; }

/* Error */
div.mage-error[generated] {
  font-size: 14px; }

/* Messages */
.message.info {
  font-size: 16px;
  font-weight: 300; }

/* Sign Up Popup - Container */
.sign-up-popup-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  z-index: 4000; }

.sign-up-popup-container .popup-signup {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 90%;
  max-width: 860px;
  min-height: 430px;
  background: #fff url("../images/bg-signup.jpg") center right no-repeat; }

.sign-up-popup-container .popup-signup .close-signup-popup {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer; }

.sign-up-popup-container .popup-signup .mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; }

/* Sign Up Popup - Detail */
.popup-signup .detail {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
  max-width: 380px; }

.popup-signup .detail h3 {
  color: #46804b;
  font-size: 28px;
  margin: 0 0 18px;
  max-width: 360px;
  font-weight: 400; }

.popup-signup .detail p {
  font-size: 16px;
  color: #222;
  line-height: 25px;
  padding-bottom: 18px; }

.popup-signup .detail form {
  position: relative; }

.popup-signup .detail form input[type=text] {
  border: 1px solid #ddd;
  color: #777;
  height: 56px;
  font-size: 16px;
  padding: 0 73px 0 15px; }

.popup-signup .detail form input[type=image] {
  position: absolute;
  top: 0;
  right: 0; }

.popup-signup .detail form input[type=text]::-webkit-input-placeholder {
  color: #777; }

.popup-signup .detail form input[type=text]::-moz-placeholder {
  color: #777; }

.popup-signup .detail form input[type=text]:-ms-input-placeholder {
  color: #777; }

.popup-signup .detail form input[type=text]:-moz-placeholder {
  color: #777; }

@media (max-width: 900px) {
  /* Newsletter Popup */
  .sign-up-popup-container .popup-signup .mask {
    background: rgba(255, 255, 255, 0.9); } }

@media (max-width: 768px) {
  /* Main */
  .page-main {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box; } }

@media (max-width: 545px) {
  /* Newsletter Popup */
  .popup-signup .detail {
    left: 20px;
    right: 20px; } }

/* Header Top */
.header-top-container {
  background: #c82d23;
  height: 35px; }

.header-top-container .header-top {
  max-width: 1540px;
  margin: 0 auto;
  width: 96%; }

/* Top Welcome/Login */
.header-top-container .header-top .header-login {
  color: #fff;
  float: left; }

.header-top-container .header-top .header-login p {
  margin: 0;
  padding: 7px 0 9px; }

.header-top-container .header-top .header-login p a {
  color: #fff;
  text-decoration: none; }

.header-top-container .header-top .header-login p a:hover {
  text-decoration: underline; }

/* Top Links */
.header-top-container .header-top .header-links {
  float: right; }

.header-top-container .header-top .header-links ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.header-top-container .header-top .header-links ul li {
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: top; }

.header-top-container .header-top .header-links ul li a {
  color: #fff;
  display: inline-block;
  vertical-align: top;
  padding: 7px 16px 9px;
  text-decoration: none;
  font-weight: 300; }

.header-top-container .header-top .header-links ul li a:hover {
  background: #e63329; }

.header-top-container .header-top .header-links {
  float: right; }

.header-top-container .header-top .header-links ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.header-top-container .header-top .header-links ul li {
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: top; }

.header-top-container .header-top .header-links ul li a {
  color: #fff;
  display: inline-block;
  vertical-align: top;
  padding: 7px 16px 9px;
  text-decoration: none;
  font-weight: 300; }

.header-top-container .header-top .header-links ul li a:hover {
  background: #e63329; }

.header-top-container .header-top .header-links ul li.link-basket {
  position: relative;
  cursor: pointer; }

.header-top-container .header-top .header-links ul li.link-basket > a {
  background: transparent !important;
  padding-right: 31px;
  background-size: 13px 18px; }

.header-top-container .header-top .header-links ul li.link-basket .bag-image {
  position: absolute;
  top: 0;
  right: -11px;
  padding: 4px 11px;
  border-radius: 150px;
  margin: 2px 0; }

.header-top-container .header-top .header-links ul li.link-basket .bag-image img {
  width: 13px;
  height: 18px;
  position: relative;
  top: 1px; }

.header-top-container .header-top .header-links ul li.link-basket:hover .bag-image {
  background: #b72922; }

/* Main Header */
.page-header {
  background: #e63329;
  border: 0;
  margin-bottom: 0; }

.header.content {
  padding: 0;
  height: 105px;
  width: 96%;
  margin: 0 auto; }

/* Search */
.header.content .block-search {
  margin-top: 28px;
  width: 400px; }

.header.content .block-search input {
  height: 50px;
  border: 0;
  font-size: 16px;
  color: #222;
  padding-left: 15px; }

.header.content .block-search .action.search {
  top: 9px; }

.header.content .block-search .action.search {
  top: 5px;
  opacity: 1; }

.header.content .block-search .action.search:before {
  content: ' ';
  width: 30px;
  height: 30px;
  background: url("../images/svg/search.svg") center center no-repeat;
  background-size: 22px 20px; }

/* Phone */
.header-phone {
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  float: right;
  margin: 40px 40px 0 0;
  padding-left: 37px;
  background: url("../images/svg/phone.svg") center left no-repeat;
  background-size: 20px 22px; }

.header-phone a {
  font-weight: 400;
  color: #fff; }

/* Logo */
.logo {
  margin: 9px 0 0 0; }

/*.logo img     { height: 86px; transition: height 0.1s }*/
@media (min-width: 1050px) {
  /* Floating Header */
  .floating-header {
    /* Layout */
    /* 187 */
    /* Header */ }
    .floating-header .page-wrapper {
      padding-top: 210px; }
    .floating-header .page-header {
      position: fixed;
      top: -35px;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 2000;
      background: #e63329; }
    .floating-header .nav-sections-new {
      position: fixed;
      width: 100%;
      top: 82px;
      left: 0;
      right: 0;
      z-index: 2500;
      box-shadow: 0px 7px 20px -11px rgba(0, 0, 0, 0.4); }
    .floating-header .logo img {
      height: 63px; }
    .floating-header .header-phone {
      margin-top: 23px; }
    .floating-header .header.content {
      height: 82px; }
    .floating-header .header.content .block-search {
      margin-top: 15px; }
    .floating-header .block-search .control {
      padding-bottom: 0; } }

@media (max-width: 1060px) {
  /* Top */
  .header-top .no-tablet {
    display: none !important; }
  /* Search */
  .header.content .block-search {
    width: 250px; } }

@media (max-width: 850px) {
  /* Search */
  .header.content .block-search {
    display: none; }
  /* Phone */
  .header-phone {
    margin-right: 0; } }

@media (max-width: 768px) {
  /* Not needed on mobile */
  .header-phone,
  .header-top-container .header-top .header-links,
  .header-top-container .header-top .header-login {
    display: none; }
  /* Logo */
  .logo {
    max-width: none; }
  /* Mobile Header */
  .header-mobile {
    display: block !important; }
  .header-mobile ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    height: 35px; }
  .header-mobile ul li {
    margin: 0;
    padding: 7px 0 0;
    text-align: center;
    width: 25%; }
  .header-mobile ul li:active {
    background: #e63329; }
  /* Search */
  .header.content {
    width: 100%;
    padding: 0 2%;
    box-sizing: border-box; }
  .mobile-search-active .header.content .block.block-search {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    z-index: 5000;
    background: #c82d23;
    padding: 20px;
    margin: 0;
    box-sizing: border-box; }
  .mobile-search-active .header.content .block.block-search .block-content {
    background: #fff; }
  .mobile-search-active .header.content .block.block-search .control {
    border-top: 0;
    padding: 0 20px; }
  .mobile-search-active .header.content .block.block-search .label {
    position: absolute;
    top: 30px;
    right: 31px;
    width: 35px;
    height: 33px;
    clip: auto; }
  .mobile-search-active .header.content .block.block-search input {
    position: static;
    margin: 0; }
  .mobile-search-active .header.content .block-search .action.search {
    display: none; }
  .mobile-search-active .block-search .label:before {
    content: ' ';
    width: 30px;
    height: 30px;
    background: url("../images/svg/search.svg") center center no-repeat;
    background-size: 22px 20px; }
  .new-navigation {
    text-align: center; } }

@media (max-width: 400px) {
  /* Search */
  .mobile-search-active .header.content .block-search input {
    padding-left: 10px; }
  /* Logo */
  .logo {
    padding-left: 10px; } }

/* Nav container */
.nav-sections-new {
  background: #eee;
  height: 50px;
  margin-bottom: 20px; }

.nav-sections-new .nav-sections-new-items {
  max-width: 1540px;
  margin: 0 auto;
  width: 98%; }

.nav-sections-new .nav-sections-new-item-title {
  display: none; }

/* Nav menu */
.new-navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between; }

.new-navigation ul li.level-top {
  margin: 0;
  padding: 0; }

.new-navigation ul li.level-top a.level-top {
  padding: 15px 0 16px;
  display: inline-block;
  vertical-align: top;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none; }

.new-navigation ul li.level-top.active a.level-top {
  color: #e63329; }

/* Subnav */
.new-navigation ul ul {
  display: none !important; }

/* Old Toggle */
.action.nav-toggle {
  display: none !important; }

@media (max-width: 1040px) {
  /* Menu */
  .new-navigation ul {
    display: none; } }

@media (min-width: 769px) and (max-width: 1040px) {
  /* Tablet Nav Toggle */
  .tablet-menu-toggle {
    display: inline-block !important;
    vertical-align: top;
    font-size: 16px;
    text-transform: uppercase;
    padding: 15px 10px 12px;
    font-weight: 400;
    text-decoration: none; }
  .tablet-menu-toggle:hover {
    text-decoration: none; }
  /* Tablet Nav */
  .new-navigation ul.open {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 2500;
    width: 100%;
    background: #fff;
    padding: 20px 0;
    display: block !important;
    box-sizing: border-box; }
  .new-navigation ul.open li.level-top a.level-top {
    padding: 8px 20px;
    display: block;
    text-transform: uppercase; }
  .new-navigation ul.open li.level-top a.level-top:hover,
  .new-navigation ul.open li.level-top a.level-top:active {
    background: #e63329;
    color: #fff; }
  .nav-sections-new {
    position: relative; } }

@media (max-width: 768px) {
  /* Mobile Navigation - Toggle */
  .mobile-nav-toggle {
    display: block !important;
    position: absolute;
    right: 15px;
    top: 35px; }
  .mobile-nav-toggle .mobile-nav-toggle-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url("../images/svg/burger.svg") center center no-repeat;
    cursor: pointer;
    transition: background 0.2s; }
  .mobile-nav-toggle .mobile-nav-toggle-link.open {
    background-image: url("../images/svg/close.svg"); }
  /* Mobile Navigation */
  .nav-sections-new {
    position: relative; }
  .new-navigation ul.open {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    width: 100%;
    background: #fff;
    z-index: 2500;
    display: block;
    padding: 15px 0;
    text-align: left;
    box-shadow: 0px 18px 25px -15px rgba(0, 0, 0, 0.3); }
  .new-navigation ul.open li.level-top a.level-top {
    color: #e63329;
    display: block;
    padding: 8px 20px; }
  .new-navigation ul.open li.level-top:active {
    background: #e63329; }
  .new-navigation ul.open li.level-top:active a {
    color: #fff; }
  /* Phone number */
  .mobile-number {
    color: #222;
    display: block !important;
    margin: 0;
    font-weight: 400;
    text-transform: uppercase;
    padding: 15px 0 0 0.5%;
    font-size: 16px; } }

.covid-action-info {
  width: 96%;
  max-width: 1540px;
  margin: 0 auto 10px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  color: #fff;
  background: #9e7490;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .covid-action-info {
      width: calc(100% - 20px); } }
  @media (min-width: 768px) {
    .covid-action-info {
      padding: 0 20px 0 50px; } }
  .covid-action-info p {
    display: inline-block;
    margin: 0;
    padding: 35px 0 35px 90px;
    font-size: 18px;
    font-weight: 400;
    background: url("/pub/media/freshveg/info-circle.svg?123") no-repeat left center;
    background-size: 68px; }

/* Container */
.banner-container {
  max-width: 1540px;
  width: 96%;
  margin: 0 auto 11px;
  padding-left: 10px;
  padding-right: 10px; }

.banner-container .banner {
  position: relative; }

/* Main Image */
.banner-container .banner .main-image-group .main-image {
  display: block; }

/* Mask */
.banner-container .banner .mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); }

/* Overlay Container */
.banner-container .banner .overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.banner-container .banner .overlay-container .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 600;
  cursor: pointer; }

.banner-container .banner .overlay-container .arrow.arrow-left {
  left: 25px; }

.banner-container .banner .overlay-container .arrow.arrow-right {
  right: 25px; }

/* Overlay */
.banner-container .banner .overlay-container .overlay {
  position: absolute;
  background: transparent;
  top: 49%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  padding: 45px 30px 45px 70px;
  width: 100%;
  max-width: 824px;
  text-align: center;
  margin: 0 auto; }

.banner-container .banner .overlay-container .overlay .title {
  color: #fff;
  font-size: 48px;
  margin: 0; }

.banner-container .banner .overlay-container .overlay .under-title {
  color: #fff; }

.banner-container .banner .overlay-container .overlay .link {
  color: #fff; }

.banner-container .banner .overlay-container .overlay .link a {
  color: #fff;
  display: inline-block;
  vertical-align: top;
  background: #46804b;
  padding: 15px 20px;
  text-transform: uppercase;
  text-align: center;
  width: 316px;
  height: 65px;
  box-sizing: border-box;
  line-height: 34px;
  text-decoration: none;
  font-size: 17px;
  border-radius: 3px; }

.banner-container .banner .overlay-container .overlay .link a:hover {
  background: #3d7041; }

/* Pager */
.banner-container .cycle-pager {
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  z-index: 600; }

.banner-container .cycle-pager span {
  display: inline-block;
  vertical-align: top;
  width: 10px;
  height: 10px;
  background: #fff;
  margin: 0 5px;
  text-indent: -999em;
  border-radius: 25px;
  cursor: pointer; }

.banner-container .cycle-pager span.cycle-pager-active {
  background: #e63329; }

/* Banner - Home */
.banner-container.banner-home .banner .overlay-container .overlay {
  background: rgba(0, 0, 0, 0.4);
  right: auto;
  text-align: left; }

.banner-container.banner-home .banner .overlay-container .overlay .title {
  margin-bottom: 30px; }

.banner-container.banner-home .banner .mask {
  background: rgba(0, 0, 0, 0.1); }

@media (max-width: 1400px) {
  /* Overlay */
  .banner-container .banner .overlay-container .overlay {
    padding: 25px 30px 32px 70px; } }

@media (max-width: 1250px) {
  /* Pager */
  .cycle-pager {
    display: none; } }

@media (max-width: 1070px) {
  /* Overlay */
  .banner-container .banner .overlay-container .overlay {
    padding: 15px 30px 17px 70px;
    box-sizing: border-box; }
  .banner-container .banner .overlay-container .overlay .title {
    font-size: 36px; }
  .banner-container .banner .overlay-container .overlay .link a {
    line-height: 34px;
    height: auto;
    min-height: 52px;
    padding: 10px 20px; }
  .banner-container.banner-home .banner .overlay-container .overlay .title {
    margin-bottom: 15px; } }

@media (max-width: 768px) {
  /* Mobile Banner */
  .banner-container {
    height: 250px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden; }
  .banner-container .banner {
    right: 0; }
  .banner-container .banner .main-image-group {
    width: 100%;
    overflow: hidden; }
  .banner-container .banner .main-image-group .main-image {
    max-width: none; }
  .banner-container .banner .overlay-container .arrow {
    display: none; }
  .banner-container .banner .overlay-container .overlay {
    padding: 15px 30px 17px 30px; }
  /* Home Banner */
  .banner-container.banner-home {
    height: 400px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden; }
  .banner-container.banner-home .main-image-group {
    width: 100%;
    overflow: hidden; }
  .banner-container.banner-home .banner .overlay-container .overlay {
    text-align: center;
    right: 0; } }

@media (max-width: 550px) {
  /* Overlay */
  .banner-container .banner .overlay-container .overlay {
    padding: 35px 20px 40px 20px; }
  .banner-container .banner .overlay-container .overlay .link a {
    width: 100%;
    box-sizing: border-box; }
  .banner-container.banner-home .banner .overlay-container .overlay .title {
    margin-bottom: 35px; } }

.banner-template-a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #ebebeb; }
  .banner-template-a::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: #000;
    opacity: 0.3; }

.banner-template-a__overlay {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 20px; }

.banner-template-a__heading {
  margin: 0;
  font-size: 38px; }
  @media (min-width: 1024px) {
    .banner-template-a__heading {
      font-size: 48px; } }

.banner-template-a__subtitle {
  margin: 10px 0 0;
  font-size: 18px; }
  @media (min-width: 1024px) {
    .banner-template-a__subtitle {
      font-size: 28px; } }

/* Product - Share */
.share-buttons-group {
  position: absolute;
  top: 28px;
  right: 0; }

/* Share Buttons */
.share-buttons {
  width: 40px;
  height: 50px;
  position: absolute;
  top: 0px;
  right: 0px; }

.share-buttons:before {
  content: 'Share';
  top: 6px;
  left: -55px;
  position: absolute;
  font-size: 14px;
  text-transform: uppercase;
  color: #222; }

.share-button {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  background: #fff;
  font-size: 1.25em;
  color: #fff;
  cursor: pointer;
  transition: margin-top 0.4s; }

.share-buttons:hover .share-button:first-child,
.share-button:hover {
  background: #ff5c3e; }

.share-buttons .expandable {
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  padding-top: 10px; }

.share-buttons:hover .expandable, .share-buttons.active .expandable {
  opacity: 1;
  visibility: visible; }

.share-buttons .expandable .share-button {
  margin-top: -15px;
  margin-bottom: 10px; }

.share-buttons:hover .expandable .share-button, .share-buttons.active .expandable .share-button {
  margin-top: 0; }

.share-button.expand {
  background: #fff !important; }

.share-button.expand:hover {
  background: #fff !important; }

.share-button.pinterest {
  background: #cc1e27; }

.share-button.pinterest:hover {
  background: #b71b23; }

.share-button.twitter {
  background: #5fc6f1; }

.share-button.twitter:hover {
  background: #55b2d8; }

.share-button.facebook {
  background: #3c5a97; }

.share-button.facebook:hover {
  background: #365188; }

.share-button.googleplus {
  background: #dc4a38; }

.share-button.googleplus:hover {
  background: #c64232; }

.share-button.email {
  background: #54c795; }

.share-button.email:hover {
  background: #4bb386; }

.list-sidebar .minicart-wrapper {
  float: none;
  margin: 0 0 30px; }
  .list-sidebar .minicart-wrapper .block-minicart {
    position: static;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    box-shadow: none;
    border: 0; }
    .list-sidebar .minicart-wrapper .block-minicart:before, .list-sidebar .minicart-wrapper .block-minicart:after {
      display: none; }
    .list-sidebar .minicart-wrapper .block-minicart .subtotal {
      display: flex;
      justify-content: space-between;
      margin: 0 30px;
      padding: 24px 0;
      font-size: 19px;
      text-align: left;
      border-bottom: 1px solid #d9d9d9; }
    .list-sidebar .minicart-wrapper .block-minicart .amount .price-wrapper:first-child .price {
      font-size: 19px;
      font-weight: 600; }
  .list-sidebar .minicart-wrapper .block-content {
    border: 1px solid #d9d9d9;
    border-top: 0; }
    .list-sidebar .minicart-wrapper .block-content > .actions {
      margin: 0;
      padding: 20px 30px 30px; }
      .list-sidebar .minicart-wrapper .block-content > .actions .action.primary, .list-sidebar .minicart-wrapper .block-content > .actions #discount-form .action, #discount-form .list-sidebar .minicart-wrapper .block-content > .actions .action, .list-sidebar .minicart-wrapper .block-content > .actions .block-authentication .actions-toolbar > .primary .action, .block-authentication .actions-toolbar > .primary .list-sidebar .minicart-wrapper .block-content > .actions .action {
        display: block;
        padding: 26px 15px;
        font-size: 19px;
        text-transform: uppercase; }
  .list-sidebar .minicart-wrapper .minicart-items-wrapper {
    height: auto !important;
    margin: 0;
    border: 0;
    padding: 30px 30px 0; }
  .list-sidebar .minicart-wrapper .minicart-items .product-item {
    padding: 10px 0;
    border-bottom: 1px solid #d9d9d9; }
    .list-sidebar .minicart-wrapper .minicart-items .product-item:not(:first-child) {
      border-top: 0; }
    .list-sidebar .minicart-wrapper .minicart-items .product-item .product-image-container {
      width: 50px !important; }
      .list-sidebar .minicart-wrapper .minicart-items .product-item .product-image-container .product-image-photo {
        width: auto !important;
        height: auto !important; }
  .list-sidebar .minicart-wrapper .minicart-items .product-item-details {
    padding-left: 72px;
    font-size: 13px; }
    .list-sidebar .minicart-wrapper .minicart-items .product-item-details .price-container .price {
      font-size: 13px; }
  .list-sidebar .minicart-wrapper .minicart-items .product-item-pricing .label {
    text-transform: uppercase;
    font-weight: 600;
    width: 3rem; }
  .list-sidebar .minicart-wrapper .product .actions {
    position: relative; }

.minicart-top {
  position: relative;
  padding: 25px;
  color: #fff;
  background-color: #e63329; }
  .minicart-top .items-total {
    position: absolute;
    top: 20px;
    right: 23px; }
    .minicart-top .items-total .label {
      display: inline-block;
      padding: 18px 12px 0 0;
      font-size: 18px;
      color: #fff; }
    .minicart-top .items-total .bag {
      position: relative;
      display: inline-block;
      width: 31px;
      height: 53px;
      text-align: center;
      background: url(../images/icon-basket-items.png) center center no-repeat;
      vertical-align: top; }
    .minicart-top .items-total .bag .count {
      position: absolute;
      top: 19px;
      right: 0;
      left: -1px;
      font-size: 18px;
      font-weight: 400;
      color: #e63329;
      text-align: center; }

.minicart-top__title {
  font-size: 28px; }

.block-minicart .qty-group {
  float: none;
  clear: both;
  display: block;
  width: 41px;
  margin-top: 60px;
  margin-left: 80px; }

.block-minicart .minicart-items .item-qty {
  margin: 0; }

.list-sidebar .minicart-wrapper .minicart-items .product-item-details {
  float: left;
  padding: 0 0 0 30px; }

.list-sidebar .minicart-wrapper .minicart-items .product-item {
  border-color: #ededed;
  padding: 20px 0; }

.minicart-items .product-item-name {
  margin: 0; }

.minicart-wrapper.mask .block-content {
  position: relative; }

.minicart-wrapper.mask .block-content:after {
  content: ' ';
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 20; }

@media (min-width: 768px) {
  .list-sidebar .minicart-wrapper .minicart-items .product-item-details {
    width: calc(100% - 80px);
    padding-bottom: 10px; } }

@media (min-width: 1480px) {
  .list-sidebar .minicart-wrapper .minicart-items .product-item-details {
    width: auto; }
  .block-minicart .qty-group {
    display: inline-block;
    width: auto;
    margin: 0;
    clear: none;
    float: right; } }

/* Layout */
.catalogsearch-result-index.page-layout-2columns-right .column.main,
.catalog-category-view.page-layout-2columns-right .column.main {
  width: 73.1%;
  float: left; }

.catalogsearch-result-index.page-layout-2columns-right .sidebar-main,
.catalog-category-view.page-layout-2columns-right .sidebar-main {
  width: 24.6%;
  float: right;
  padding-left: 0; }

/* Hide Filters */
.catalog-category-view.page-layout-2columns-right .block.filter {
  display: none; }

/* Toolbars */
.toolbar-products {
  display: none; }

/* Hide Messages */
.catalog-category-view .message.success {
  display: none !important; }

/* Child Letters */
.child-letters {
  list-style: none;
  margin: 0 0 25px;
  padding: 5px 0;
  text-align: center;
  background: #f5f5f5; }

.child-letters li {
  margin: 0;
  padding: 0;
  font-size: 14px;
  display: inline-block;
  vertical-align: top; }

.child-letters li a {
  color: #222;
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
  padding: 6px 11px;
  border-radius: 55px;
  cursor: pointer;
  margin: 0 5px; }

.child-letters li a:hover {
  background: #46804b;
  color: #fff; }

/* Items - Basket */
.items-basket {
  height: 43px; }

.items-basket .label {
  color: #fff;
  font-size: 18px;
  padding: 18px 12px 0 0;
  display: inline-block; }

.items-basket .bag {
  display: inline-block;
  vertical-align: top;
  height: 53px;
  width: 31px;
  background: url("../images/icon-basket-items.png") center center no-repeat;
  position: relative;
  text-align: center; }

.items-basket .bag .quantity {
  position: absolute;
  text-align: center;
  top: 19px;
  left: -1px;
  right: 0;
  color: #e63329;
  font-size: 18px;
  font-weight: 400; }

/* Category List */
.category-list {
  list-style: none;
  margin: 0 0 40px;
  padding: 0; }

.category-list li {
  margin: 0;
  padding: 0; }

.category-list .category-item .category-link {
  display: block;
  background: #89bba4 url("../images/arrow-white-down.png") 98% 50% no-repeat;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 20px;
  margin-bottom: 15px; }

.category-list .category-item.category-empty .category-link {
  background-color: #89bba4; }

.category-list .category-item .product-list {
  padding: 0 0 20px; }

.category-list .category-item .category-link.active {
  background-image: url("../images/arrow-white-up.png"); }

.category-heading-columns {
  margin-bottom: 18px;
  padding: 0 10px; }
  @media (max-width: 1199px) {
    .category-heading-columns {
      display: none; } }
  .category-heading-columns ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none; }
  .category-heading-columns li {
    text-transform: uppercase; }

.product-list .col {
  padding: 0 14px;
  box-sizing: border-box; }

@media (min-width: 1200px) {
  .product-list .col.col-image {
    flex-shrink: 0;
    width: 90px; } }

@media (min-width: 1200px) {
  .product-list .col.col-description {
    flex: 0 0 38%; } }

@media (min-width: 1200px) {
  .product-list .col.col-options {
    flex: 0 0 15%; } }

@media (min-width: 1200px) {
  .product-list .col.col-qty {
    flex: 0 0 18%; } }

@media (min-width: 1200px) {
  .product-list .col.col-price {
    flex: 0 0 5%; } }

/* Product List */
.category-list .category-item .product-list ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.category-list .category-item .product-list ul li {
  padding: 10px; }

.category-list .category-item .product-list ul li:nth-child(2n) {
  background: #f5f5f5; }

.category-list .category-item .product-list ul li form {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1200px) {
    .category-list .category-item .product-list ul li form {
      flex-wrap: nowrap; } }

.category-list .category-item .product-list ul li .col {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.category-list .category-item .product-list ul li .col-image {
  width: 90px; }

.category-list .category-item .product-list ul li .col-description {
  width: 100%; }
  @media (min-width: 500px) {
    .category-list .category-item .product-list ul li .col-description {
      margin-bottom: 10px; } }
  @media (min-width: 1200px) {
    .category-list .category-item .product-list ul li .col-description {
      margin-bottom: 0; } }

.category-list .category-item .product-list ul li .col-options {
  width: 100%;
  margin: 10px 0; }
  @media (min-width: 500px) {
    .category-list .category-item .product-list ul li .col-options {
      float: left;
      width: 25%;
      margin: 0; } }

.category-list .category-item .product-list ul li .col-options select {
  max-width: 160px;
  height: 28px;
  color: #222;
  background: #fff url("../images/arrow-green-down-small.png") 95% 50% no-repeat;
  border: 1px solid #d9d9d9;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 4px;
  padding-left: 10px;
  box-sizing: border-box; }

.category-list .category-item .product-list ul li .col-qty {
  width: 100%; }
  @media (min-width: 500px) {
    .category-list .category-item .product-list ul li .col-qty {
      width: 25%; } }
  .category-list .category-item .product-list ul li .col-qty .qty-group {
    width: 40px; }

.category-list .category-item .product-list ul li .col-price {
  width: 100%;
  margin: 10px 0; }
  @media (min-width: 500px) {
    .category-list .category-item .product-list ul li .col-price {
      width: 25%;
      margin: 0; } }

.category-list .category-item .product-list ul li .col-add {
  width: 100%;
  padding-right: 0; }
  @media (min-width: 500px) {
    .category-list .category-item .product-list ul li .col-add {
      width: 25%; } }

.category-list .category-item .product-list ul li .col-add .action.primary, .category-list .category-item .product-list ul li .col-add #discount-form .action, #discount-form .category-list .category-item .product-list ul li .col-add .action, .category-list .category-item .product-list ul li .col-add .block-authentication .actions-toolbar > .primary .action, .block-authentication .actions-toolbar > .primary .category-list .category-item .product-list ul li .col-add .action {
  text-transform: uppercase;
  margin-right: 4px;
  min-width: 75px; }

/* Qty Group */
.qty-group {
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 0 30px;
  position: relative;
  margin-top: 2px; }

.qty-group input[type='text'] {
  width: 40px;
  height: 26px;
  padding: 0;
  text-align: center;
  border: 0;
  font-size: 14px;
  color: #222; }

.qty-group .adjust-qty {
  width: 30px;
  height: 28px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  cursor: pointer; }

.qty-group .adjust-qty.minus {
  background: url("../images/icon-minus.png") center center no-repeat;
  left: 0; }

.qty-group .adjust-qty.plus {
  background: url("../images/icon-plus.png") center center no-repeat;
  right: 0; }

.qty-group .adjust-qty:active {
  background-color: #ccc; }

/* Subbox - Order Methods */
.list-sidebar .sub-box.order-methods {
  border: 1px solid #d9d9d9;
  padding: 25px;
  margin-bottom: 60px;
  background: #f5f5f5; }

.list-sidebar .sub-box.order-methods h3 {
  padding-bottom: 25px;
  font-size: 28px; }

.list-sidebar .sub-box.order-methods ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.list-sidebar .sub-box.order-methods ul li {
  margin: 0 0 20px;
  padding: 0 0 0 70px;
  min-height: 51px;
  font-size: 20px;
  font-weight: 300;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 53px 49px; }

.list-sidebar .sub-box.order-methods ul li span {
  display: inline-block;
  vertical-align: top;
  padding: 10px 0 0 0; }

/* Search results */
.catalogsearch-result-index .block.filter {
  display: none !important; }

@media (max-width: 1000px) {
  /* List */
  .category-list .category-item .product-list ul li {
    border-bottom: 1px solid #ddd; }
  .category-list .category-item .product-list ul li:last-child {
    border-bottom: 0; } }

@media (min-width: 768px) and (max-width: 895px) {
  /* Sub Box Methods */
  .list-sidebar .sub-box.order-methods ul li {
    background-image: none !important;
    padding: 0 0 20px;
    border-bottom: 1px solid #ccc;
    min-height: 0; }
  .list-sidebar .sub-box.order-methods ul li:last-child {
    border: 0; } }

@media (max-width: 768px) {
  /* Layout */
  .catalog-category-view.page-layout-2columns-right .sidebar-main {
    order: 2; }
  /* Category */
  .category-list .category-item .category-link {
    background-position: 97% 50%; } }

@media (max-width: 500px) {
  /* Category */
  .category-list .category-item .category-link {
    background-position: 94% 50%; } }

@media (min-width: 769px) {
  .subcat-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -5px; } }

.subcat-listing__item {
  padding-bottom: 10px;
  box-sizing: border-box; }
  @media (min-width: 769px) {
    .subcat-listing__item {
      flex: 1 1 50%;
      padding: 0 5px 10px; } }

.product.info.detailed {
  display: none; }

.cart-heading ul {
  list-style: none; }

/* Order Methods */
@media (min-width: 930px) {
  /* Cart */
  .cart-heading {
    background: #f5f5f5;
    border: 1px solid #d9d9d9;
    padding: 0 45px;
    margin-bottom: 35px;
    height: 120px;
    margin-top: 10px; }
  .cart-heading h2 {
    font-size: 28px;
    color: #222;
    font-weight: 400;
    float: left;
    margin: 45px 0 0 0; }
  .cart-heading ul {
    list-style: none;
    margin: 31px 0 0 0;
    padding: 0;
    float: right;
    width: 70%;
    display: flex;
    justify-content: space-between; }
  .cart-heading ul li {
    font-size: 20px;
    color: #222;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    height: 59px;
    padding: 15px 0 0 75px;
    box-sizing: border-box; }
  .cart-heading ul li.order-by-phone {
    background: url("../images/icon-order-methods-large_03.png") left center no-repeat; }
  .cart-heading ul li.order-by-fax {
    background: url("../images/icon-order-methods-large_05.png") left center no-repeat; }
  .cart-heading ul li.order-by-email {
    background: url("../images/icon-order-methods-large_07.png") left center no-repeat; } }

@media (max-width: 930px) {
  /* Cart */
  .cart-heading {
    background: #f5f5f5;
    border: 1px solid #d9d9d9;
    padding: 0 45px 0 0;
    margin-bottom: 35px;
    height: 120px;
    margin-top: 10px; }
  .cart-heading ul li > span {
    display: inline-block;
    vertical-align: top;
    font-size: 20px;
    color: #222;
    padding: 16px 85px; }
  .cart-heading ul li.order-by-phone > span {
    background: url("../images/icon-order-methods-large_03.png") left center no-repeat; }
  .cart-heading ul li.order-by-fax > span {
    background: url("../images/icon-order-methods-large_05.png") left center no-repeat; }
  .cart-heading ul li.order-by-email > span {
    background: url("../images/icon-order-methods-large_07.png") left center no-repeat; } }

/* Your Order */
.cart-your-order {
  background: #e63329;
  padding: 0 31px;
  margin-bottom: 20px;
  height: 85px;
  box-sizing: border-box;
  position: relative; }

.cart-your-order h1 {
  color: #fff;
  margin: 0;
  padding: 27px 0 0 0;
  font-size: 28px; }

.cart-your-order .items-basket {
  position: absolute;
  top: 16px;
  right: 30px; }

/* Cart */
.cart-container .form-cart {
  background: #f5f5f5;
  width: 73%; }

.cart-container .form-cart .table-wrapper {
  padding: 0 22px; }

.cart-container .form-cart .main.actions {
  padding: 15px 32px;
  background: #eee; }

/* Pagination */
.cart-products-toolbar {
  padding: 0 22px; }

.cart-products-toolbar-top {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dbdbdb; }

.cart-products-toolbar-bottom {
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #dbdbdb; }

.cart-products-toolbar .pager {
  display: flex;
  justify-content: space-between;
  align-items: center; }

/* Col Headings */
.cart.table-wrapper th.col {
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 22px; }

.cart.table-wrapper th.col.sku {
  width: 10%; }

.cart.table-wrapper th.col.item {
  width: 30%; }

.cart.table-wrapper th.col.qty span {
  padding-right: 54px; }

/* Cart Columns */
.cart.items .action-delete {
  color: #9d9d9d; }

.cart.items .product-item-details .gift-options-cart-item {
  display: none; }

.cart.items .item-actions {
  display: none; }

/* Cart Items */
.cart.table-wrapper .items thead + .item,
.cart.table-wrapper .items > .item {
  border-color: #dbdbdb; }

.cart.table-wrapper .items > .item:last-child {
  border-bottom: 0; }

.cart.table-wrapper .col {
  padding: 5px 0; }

/* Cart Qty */
.cart .qty-group input[type=text] {
  height: 28px; }

.cart .qty-group .adjust-qty {
  height: 28px; }

/* Cart Items Options */
.cart.table-wrapper .col-code span,
.cart.table-wrapper .product-item-details,
.cart.table-wrapper .col-options span,
.cart.table-wrapper .col.action-remove a {
  position: relative;
  top: 5px; }

.cart.table-wrapper .item-options {
  font-size: 14px; }

.cart.table-wrapper .item-options dt {
  font-weight: 400; }

.cart.table-wrapper .product-item-name + .item-options {
  margin: 0; }

.action-towishlist {
  display: none; }

/* Continue Links */
.form-cart .actions .continue-links {
  float: left;
  font-size: 16px;
  text-transform: uppercase;
  padding-top: 13px; }

.form-cart .actions .continue-links .sep {
  color: #46804b;
  display: inline-block;
  vertical-align: top;
  margin: 0 7px; }

.form-cart .actions .continue-links .continue-shopping,
.form-cart .actions .continue-links .clear-bag {
  background: transparent;
  text-transform: uppercase;
  border: 0;
  font-weight: 400;
  font-family: "proxima-nova", Arial, sans-serif;
  font-size: 16px;
  padding: 0;
  line-height: 23px;
  color: #46804b; }

.form-cart .actions .continue-links .clear-bag {
  margin-top: -1px; }

.form-cart .actions .continue-links .clear-bag:hover {
  text-decoration: underline; }

/* Update Links */
.update-links {
  float: right; }

.update-links .general-link,
.update-links .update-bag {
  background: #fff;
  border: 0;
  font-weight: 400;
  text-transform: uppercase;
  color: #46804b;
  padding: 15px 25px;
  font-size: 16px;
  margin-left: 10px;
  font-family: "proxima-nova", Arial, sans-serif; }

.update-links .update-bag.active {
  background: #46804b;
  color: #fff; }

.update-links .general-link:active,
.update-links .general-link:hover,
.update-links .update-bag:active,
.update-links .update-bag:hover {
  background: #46804b;
  color: #fff; }

/* Summary */
.cart-summary {
  padding: 120px 25px 25px 25px;
  width: 24.5%;
  top: 0 !important;
  position: relative; }

.cart-summary > .title {
  background: #e63329;
  color: #fff;
  margin: 0 0 20px 0;
  font-size: 28px;
  font-weight: 300;
  padding: 27px 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

/* Summary - Checkout Options */
.checkout-options {
  background: #fff;
  padding: 20px; }

.checkout-options .options {
  margin: 0 auto;
  padding-top: 5px; }

.checkout-options .options select {
  background: #d9d9d9 url("../images/svg/arrow.svg") 95% 50% no-repeat;
  background-size: 13px 8px;
  height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  padding: 0 15px;
  border-radius: 3px; }

.checkout-options p {
  font-size: 14px;
  text-align: center;
  margin: 0 0 16px; }

.checkout-options .actions {
  padding-top: 20px;
  margin: 0 auto; }

.checkout-options .actions .action.primary, .checkout-options .actions #discount-form .action, #discount-form .checkout-options .actions .action, .checkout-options .actions .block-authentication .actions-toolbar > .primary .action, .block-authentication .actions-toolbar > .primary .checkout-options .actions .action {
  width: 100%;
  box-sizing: border-box;
  font-size: 19px;
  text-transform: uppercase;
  text-align: center;
  padding: 18px 0;
  line-height: 21px; }

/* No Items */
.cart-empty {
  font-size: 16px; }

@media (max-width: 1450px) {
  /* Actions */
  .update-links .general-link,
  .update-links .update-bag {
    padding: 15px 11px; } }

@media (max-width: 1320px) {
  /* Actions */
  .update-links {
    float: left;
    width: 100%;
    text-align: left;
    margin-top: 10px; }
  .update-links button:first-child {
    margin-left: 0; }
  .form-cart .actions .continue-links {
    padding-top: 0; } }

@media (max-width: 1200px) {
  /* Cart */
  .cart.table-wrapper th.col.item {
    width: 33%; }
  .cart.table-wrapper th.col.qty {
    width: 17%; } }

@media (max-width: 1160px) {
  /* Heading */
  .cart-heading {
    height: auto; }
  .cart-heading h2 {
    display: none; }
  .cart-heading ul {
    width: 100%;
    margin: 14px 0 13px 0; } }

@media (max-width: 940px) {
  /* Columns */
  .cart.table-wrapper .col {
    display: block;
    padding: 0; }
  .cart.table-wrapper .col.col-code {
    padding-top: 10px; }
  .cart.table-wrapper .col.col.qty {
    position: relative;
    top: -70px; }
  .cart.table-wrapper .col .option-label {
    display: inline !important; }
  .cart.table-wrapper .product-item-name {
    margin: 0; }
  /* Headers */
  #shopping-cart-table thead {
    display: none; }
  /* Remove links */
  .col.action-remove {
    display: none !important; }
  .alt-remove-link {
    display: block !important; } }

@media (max-width: 900px) {
  /* Cart */
  .cart-container .form-cart .table-wrapper {
    padding: 0 15px; }
  .cart-container .form-cart .main.actions {
    padding: 15px; }
  /* Buttons */
  .update-links .general-link,
  .update-links .update-bag {
    margin-left: 1px;
    padding: 15px 6px; } }

@media (max-width: 768px) {
  /* Layout */
  .checkout-cart-index .columns {
    display: block; }
  .cart-container .form-cart,
  .cart-summary {
    width: 100%;
    float: none;
    margin-top: 30px; }
  /* Table */
  #shopping-cart-table thead {
    display: none; }
  .cart-container .form-cart .table-wrapper {
    padding: 0 10px 0 20px;
    box-sizing: border-box; }
  /* Items */
  .cart.table-wrapper .items > .item {
    border-top: 0 !important; }
  .cart.table-wrapper .col {
    width: 100% !important; }
  .cart.table-wrapper .col.col.qty {
    text-align: right;
    position: relative;
    top: -70px; }
  /* Headings */
  .cart-heading ul {
    padding-left: 20px; }
  .cart-heading ul li {
    margin: 0 0 10px; }
  .cart-heading ul li > span {
    padding: 0px 15px 0px 85px;
    height: 63px;
    font-size: 16px;
    box-sizing: border-box;
    padding-top: 10px; }
  /* Before */
  .cart.table-wrapper .col.qty:before {
    display: none; }
  /* Buttons */
  .update-links .general-link,
  .update-links .update-bag {
    padding: 15px 10px;
    margin-left: 8px; } }

@media (max-width: 610px) {
  /* Actions */
  .update-links .general-link,
  .update-links .update-bag {
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 10px; }
  .form-cart .actions .continue-links {
    float: none;
    text-align: center;
    margin: 0 auto; } }

@media (max-width: 360px) {
  /* Qty */
  .cart.table-wrapper .col.col.qty {
    top: 0;
    text-align: left;
    margin: 14px 0 20px; }
  .form-cart .actions .continue-links a {
    display: block;
    text-align: center; }
  .form-cart .actions .continue-links .sep {
    display: none; } }

@media (max-width: 345px) {
  /* Basket */
  .cart-your-order .items-basket {
    display: none; } }

/* CTA */
.cta-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px; }

.cta-container .cta {
  position: relative;
  margin-right: 11px; }

.cta-container .cta .background {
  display: block; }

.cta-container .cta .mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  transition: background 0.2s; }

.cta-container .cta:hover .mask {
  background: rgba(0, 0, 0, 0.4); }

.cta-container .cta:nth-child(3n) {
  margin-right: 0; }

/* Overlay */
.cta-container .cta .overlay {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center; }

.cta-container .cta .overlay h2 {
  color: #fff;
  font-size: 30px; }

/* Action */
@media (max-width: 950px) {
  .cta-container .cta .overlay h2 {
    font-size: 24px;
    padding: 0 14px; } }

@media (max-width: 768px) {
  /* Banner CTA */
  .cta-container {
    display: block;
    margin-bottom: 30px; }
  .cta-container .cta {
    height: 130px;
    overflow: hidden;
    margin: 0 0 10px 0; }
  .cta-container .cta .background {
    width: 100%; } }

.cta-home-top {
  width: 96%;
  max-width: 1540px;
  margin: 0 auto 10px; }
  @media (max-width: 768px) {
    .cta-home-top {
      width: calc(100% - 20px); } }
  @media (min-width: 769px) {
    .cta-home-top {
      display: flex;
      justify-content: space-between; } }

.cta-home-top__item {
  padding-bottom: 10px;
  box-sizing: border-box; }
  .cta-home-top__item:last-child {
    padding: 0; }
  @media (min-width: 769px) {
    .cta-home-top__item {
      flex: 1 1 50%;
      padding: 0 11px 0 0; } }

.cta-template-a {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ebebeb; }
  @media (min-width: 1024px) {
    .cta-template-a {
      flex-direction: row;
      align-items: center;
      height: 340px; } }

.cta-template-a__left {
  padding: 33px 20px;
  text-align: center; }
  @media (min-width: 1024px) {
    .cta-template-a__left {
      flex: 1 1 50%;
      padding: 20px 40px 20px 40px;
      text-align: left; } }
  .cta-template-a__left .action.primary, .cta-template-a__left #discount-form .action, #discount-form .cta-template-a__left .action, .cta-template-a__left .block-authentication .actions-toolbar > .primary .action, .block-authentication .actions-toolbar > .primary .cta-template-a__left .action {
    min-width: 240px;
    padding: 21px 10px;
    font-size: 17px;
    text-transform: uppercase;
    text-align: center; }
    .cta-template-a__left .action.primary::before, .cta-template-a__left #discount-form .action::before, #discount-form .cta-template-a__left .action::before, .cta-template-a__left .block-authentication .actions-toolbar > .primary .action::before, .block-authentication .actions-toolbar > .primary .cta-template-a__left .action::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      overflow: hidden;
      white-space: nowrap; }

.cta-template-a__right {
  height: 170px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  @media (max-width: 1023px) {
    .cta-template-a__right {
      order: -1; } }
  @media (min-width: 1024px) {
    .cta-template-a__right {
      flex: 1 1 50%;
      height: 100%; } }

.cta-template-a__heading {
  margin: 0 0 30px;
  font-size: 27px;
  line-height: 1.3; }
  @media (min-width: 1024px) {
    .cta-template-a__heading {
      font-size: 30px; } }

.category-cta-container {
  width: 96%;
  max-width: 1540px;
  margin: 0 auto 50px; }
  @media (max-width: 768px) {
    .category-cta-container {
      width: calc(100% - 20px); } }
  @media (min-width: 769px) {
    .category-cta-container {
      display: flex;
      justify-content: space-between; } }

.category-cta-container__item {
  padding-bottom: 10px;
  box-sizing: border-box; }
  .category-cta-container__item:last-child {
    padding: 0; }
  @media (min-width: 769px) {
    .category-cta-container__item {
      flex: 1 1 33.33333%;
      padding: 0 11px 0 0; } }

.cta-template-b {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  background-color: #ebebeb; }

.cta-template-b__image {
  height: 170px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.cta-template-b__heading {
  display: block;
  padding: 33px 20px;
  margin: 0;
  font-size: 27px;
  line-height: 1.3; }
  .cta-template-b__heading:hover, .cta-template-b__heading:focus {
    color: #222; }
  .cta-template-b__heading::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    white-space: nowrap; }

/* Layout */
.cms-page-view.page-layout-2columns-right .sidebar-main {
  width: 32.4%;
  float: right; }

.cms-page-view.page-layout-2columns-right .column.main {
  width: 59.7%; }

.cms-page-view.page-layout-2columns-right .page-main {
  margin-top: 3px; }

/* Banner */
.cms-page-view.page-layout-2columns-right .banner-container {
  margin-bottom: 19px; }

/* Content */
.cms-page-view .content-container {
  font-size: 16px;
  line-height: 24px; }

.cms-page-view .content-container p {
  margin: 0 0 14px; }

.cms-page-view .content-container .action.primary, .cms-page-view .content-container #discount-form .action, #discount-form .cms-page-view .content-container .action, .cms-page-view .content-container .block-authentication .actions-toolbar > .primary .action, .block-authentication .actions-toolbar > .primary .cms-page-view .content-container .action {
  display: inline-block;
  text-decoration: none;
  width: 188px;
  height: 54px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 17px;
  box-sizing: border-box;
  margin-top: 20px; }

/* Sidebar */
.cms-sidebar {
  margin-bottom: 70px;
  padding-top: 15px; }

.cms-sidebar img {
  display: block;
  margin-bottom: 15px; }

/* Decorated list */
ul.decorated {
  margin: 35px 0;
  padding: 30px 0 30px 50px;
  list-style: none;
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7; }

ul.decorated li {
  margin: 0;
  background: url("../images/icon-tick-red.png") left center no-repeat;
  padding: 11px 0 10px 45px;
  font-weight: 300; }

@media (min-width: 1500px) {
  /* Decorated List */
  ul.decorated {
    padding: 30px 0 30px 310px; } }

/* Content */
.cms-index-index .content-container {
  padding: 80px 545px 90px 40px;
  background: #ebebeb url("../images/bg-content-lorry.png") top right no-repeat;
  margin-bottom: 10px; }

.cms-index-index .content-container h1.page-title {
  font-size: 38px;
  color: #46804b;
  padding-bottom: 15px;
  margin-bottom: 0;
  font-weight: 400; }

.cms-index-index .content-container p {
  font-size: 20px;
  font-weight: 300; }

.cms-index-index .content-container a.action.primary, .cms-index-index .content-container #discount-form a.action, #discount-form .cms-index-index .content-container a.action, .cms-index-index .content-container .block-authentication .actions-toolbar > .primary a.action, .block-authentication .actions-toolbar > .primary .cms-index-index .content-container a.action {
  display: inline-block;
  text-decoration: none;
  padding: 18px 35px;
  font-size: 17px;
  margin-top: 20px; }

/* Opening Hours / Telephone CTA */
.contact-cta-container {
  margin-bottom: 48px; }

/* Opening Hours */
.contact-cta-container .contact-cta.cta-opening-hours {
  background: url("../images/cta_contact_03.png") top left no-repeat;
  width: 49.676%;
  height: 250px;
  float: left;
  position: relative;
  color: #fff; }

.contact-cta-container .contact-cta.cta-opening-hours .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 25px 35px; }

.contact-cta-container .contact-cta.cta-opening-hours h2 {
  color: #fff;
  font-size: 30px;
  float: left;
  width: 40%;
  padding-bottom: 25px;
  line-height: 36px;
  margin: 17px 0 12px; }

.contact-cta-container .contact-cta.cta-opening-hours .action.primary, .contact-cta-container .contact-cta.cta-opening-hours #discount-form .action, #discount-form .contact-cta-container .contact-cta.cta-opening-hours .action, .contact-cta-container .contact-cta.cta-opening-hours .block-authentication .actions-toolbar > .primary .action, .block-authentication .actions-toolbar > .primary .contact-cta-container .contact-cta.cta-opening-hours .action {
  float: left;
  clear: left;
  text-transform: uppercase;
  background: #1b4820;
  border: 2px solid #fff;
  padding: 16px 38px;
  font-size: 17px; }

.contact-cta-container .contact-cta.cta-opening-hours table {
  padding: 0;
  color: #fff;
  width: 50%;
  float: right;
  font-size: 16px; }

.contact-cta-container .contact-cta.cta-opening-hours table tr td {
  padding: 7px 0 7px;
  border-bottom: 1px solid #fff; }

.contact-cta-container .contact-cta.cta-opening-hours table tr:last-child td {
  border-bottom: 0; }

/* Telephone */
.contact-cta-container .contact-cta.cta-phone {
  background: url("../images/cta_contact_05.png") top left no-repeat;
  width: 49.676%;
  height: 250px;
  float: right;
  position: relative;
  color: #fff;
  text-align: center; }

.contact-cta-container .contact-cta.cta-phone .overlay {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15%;
  right: 15%;
  bottom: 0; }

.contact-cta-container .contact-cta.cta-phone h2 {
  font-size: 30px;
  padding-bottom: 20px;
  margin-top: 0;
  padding-top: 0; }

.contact-cta-container .contact-cta.cta-phone p {
  font-size: 23px;
  margin: 0; }

/* Testimonials */
.testimonials-container {
  padding-bottom: 80px; }

.testimonials {
  width: 49.7%;
  float: left;
  text-align: center;
  border-right: 1px solid #c8c8c8; }

.testimonials h2 {
  font-weight: 600;
  padding-bottom: 18px;
  font-size: 21px; }

.testimonials p {
  margin: 0 0 16px;
  line-height: 27px;
  font-size: 16px;
  font-style: italic; }

.testimonials p.author {
  font-style: normal; }

.testimonials p strong {
  font-style: normal; }

.testimonal-slider {
  position: relative; }

.testimonal-slider .quote-open {
  position: absolute;
  top: 0;
  left: 30px; }

.testimonal-slider .quote-close {
  position: absolute;
  right: 30px;
  bottom: 0; }

.testimonal-slider .testimonial {
  max-width: 385px;
  margin: 0 auto;
  right: 0; }

.testimonal-slider .testimonial-pager {
  list-style: none;
  margin: 0;
  padding: 20px 0 0 0; }

.testimonal-slider .testimonial-pager span {
  display: inline-block;
  vertical-align: top;
  width: 10px;
  height: 10px;
  background: #e6e7e8;
  border-radius: 35px;
  text-indent: -999em;
  margin: 0 5px;
  cursor: pointer; }

.testimonal-slider .testimonial-pager span.cycle-pager-active {
  background: #e63329; }

/* Testimonials Info */
.testimonials-container .testiminals-info {
  width: 45.7%;
  float: right; }

.testimonials-container .testiminals-info h2 {
  font-size: 21px;
  color: #46804b;
  font-weight: 600;
  padding-bottom: 18px; }

.testimonials-container .testiminals-info p {
  font-size: 16px;
  line-height: 25px; }

@media (max-width: 1250px) {
  /* Testimonials */
  .testimonal-slider .quote-open,
  .testimonal-slider .quote-close {
    width: 40px;
    height: 40px; } }

@media (max-width: 1100px) {
  /* Content */
  .cms-index-index .content-container {
    background-position: 130% 50%;
    padding-right: 415px; } }

@media (max-width: 860px) {
  /* Content */
  .cms-index-index .content-container {
    background-image: none;
    padding: 40px 25px; } }

@media (min-width: 768px) and (max-width: 1200px) {
  /* Opening Hours CTA */
  .contact-cta-container .contact-cta.cta-opening-hours {
    height: 450px;
    background-size: cover;
    display: flex;
    flex-wrap: wrap; }
  .contact-cta-container .contact-cta.cta-opening-hours .overlay {
    display: flex;
    flex-wrap: wrap; }
  .contact-cta-container .contact-cta.cta-opening-hours h2 {
    order: 1;
    width: 100%;
    padding-bottom: 0; }
  .contact-cta-container .contact-cta.cta-opening-hours table {
    order: 2;
    width: 100%; }
  .contact-cta-container .contact-cta.cta-opening-hours .action.primary, .contact-cta-container .contact-cta.cta-opening-hours #discount-form .action, #discount-form .contact-cta-container .contact-cta.cta-opening-hours .action, .contact-cta-container .contact-cta.cta-opening-hours .block-authentication .actions-toolbar > .primary .action, .block-authentication .actions-toolbar > .primary .contact-cta-container .contact-cta.cta-opening-hours .action {
    order: 3;
    height: 50px !important; }
  .contact-cta-container .contact-cta.cta-phone {
    height: 450px;
    background-size: cover; } }

@media (max-width: 768px) {
  /* Contact CTA */
  .contact-cta-container .contact-cta.cta-phone,
  .contact-cta-container .contact-cta.cta-opening-hours {
    width: 100%;
    margin-bottom: 10px; }
  .contact-cta-container .contact-cta.cta-phone {
    height: 250px;
    background-size: cover; }
  .contact-cta-container .contact-cta.cta-phone .overlay {
    bottom: auto; }
  /* Testimonials */
  .testimonials {
    width: 100%;
    margin-bottom: 45px;
    border-right: 0;
    border-bottom: 1px solid #eee;
    padding-bottom: 45px; }
  .testimonials-container .testiminals-info {
    width: 100%; }
  .testimonials-container {
    padding-bottom: 20px; }
  .testimonials h2 {
    padding: 0 57px 18px; }
  /* Quotes */
  .testimonal-slider .quote-open {
    left: 5px; }
  .testimonal-slider .quote-close {
    right: 5px; } }

@media (max-width: 580px) {
  /* Contact CTA */
  .contact-cta-container .contact-cta.cta-opening-hours {
    height: 430px;
    background-size: cover; }
  .contact-cta-container .contact-cta.cta-opening-hours .overlay {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 25px 5%; }
  .contact-cta-container .contact-cta.cta-opening-hours h2 {
    width: 100%;
    order: 1;
    padding-bottom: 0;
    margin-bottom: 0; }
  .contact-cta-container .contact-cta.cta-opening-hours table {
    width: 100%;
    order: 2; }
  .contact-cta-container .contact-cta.cta-opening-hours .action.primary, .contact-cta-container .contact-cta.cta-opening-hours #discount-form .action, #discount-form .contact-cta-container .contact-cta.cta-opening-hours .action, .contact-cta-container .contact-cta.cta-opening-hours .block-authentication .actions-toolbar > .primary .action, .block-authentication .actions-toolbar > .primary .contact-cta-container .contact-cta.cta-opening-hours .action {
    order: 3;
    padding: 16px 38px 16px; } }

@media (max-width: 450px) {
  /* Contact CTA */
  .contact-cta-container .contact-cta.cta-phone .overlay {
    left: 5%;
    right: 5%; } }

@media (max-width: 350px) {
  /* Contact CTA */
  .contact-cta-container .contact-cta.cta-opening-hours .overlay {
    padding: 25px 20px; } }

/* List */
.post-list {
  list-style: none;
  margin: 0;
  padding: 0; }

.post-list li {
  margin: 0 0.71% 20px 0;
  padding: 0;
  width: 32.857%;
  float: left;
  background: #eee;
  cursor: pointer; }

.post-list li:nth-child(3n) {
  margin-right: 0; }

.post-list li .post-detail {
  padding: 30px 40px;
  font-size: 16px;
  color: #222; }

.post-list li .post-detail p {
  margin: 0; }

.post-list li .post-title {
  font-size: 28px;
  padding-bottom: 8px;
  margin: 0; }

.post-list li .post-title a {
  color: #46804b;
  text-decoration: none; }

.post-list li .post-ftimg-hld {
  position: relative; }

.post-list li .post-ftimg-hld img {
  display: block;
  width: 100%; }

.post-list li .post-ftimg-hld .mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); }

/* Post List Hover */
.post-list li:hover {
  background: #46804b;
  color: #fff; }

.post-list li:hover .post-title a {
  color: #fff; }

.post-list li:hover .post-detail {
  color: #fff; }

/* Post View Layout */
.blog-post-view.page-layout-2columns-right .column.main {
  width: 59.4%;
  float: left; }

.blog-post-view.page-layout-2columns-right .sidebar-main {
  width: 32.46%;
  float: right; }

.post-text-hld {
  padding: 10px 0; }

/* News Filter */
.news-filter {
  width: 350px;
  float: right;
  margin-bottom: 25px; }

.news-filter select {
  height: 51px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 0 15px;
  font-size: 16px;
  color: #757575;
  border-radius: 3px;
  background: url("../images/svg/arrow-red.svg") 95% 50% no-repeat;
  background-size: 17px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

/* Post View */
.post-nextprev-hld {
  display: none; }

.post-description p {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 18px; }

.post-description .action.primary, .post-description #discount-form .action, #discount-form .post-description .action, .post-description .block-authentication .actions-toolbar > .primary .action, .block-authentication .actions-toolbar > .primary .post-description .action {
  min-width: 188px;
  height: 54px;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 37px;
  margin-top: 15px; }

.post-detail-footer {
  position: relative;
  margin-bottom: 30px; }

/* Post View Sidebar */
.post-gallery {
  margin-bottom: 70px; }

.post-gallery img {
  display: block;
  margin-bottom: 11px;
  width: 100%; }

@media (max-width: 880px) {
  /* List */
  .post-list li {
    width: 49%;
    margin-right: 1.5%; }
  .post-list li:nth-child(3n) {
    margin-right: 1.5%; }
  .post-list li:nth-child(2n) {
    margin-right: 0; } }

@media (max-width: 768px) {
  /* Select category */
  .news-filter {
    width: 100%;
    box-sizing: border-box; }
  .news-filter select {
    background-position: 97% 50%; } }

@media (max-width: 575px) {
  /* List */
  .post-list li {
    width: 100%;
    margin-right: 0 !important;
    min-height: 0 !important; } }

@media (max-width: 340px) {
  /* Share */
  .post-detail-footer {
    padding-top: 70px; }
  .share-buttons-group {
    left: 97px;
    right: auto; } }

/* Layout */
.contact-index-index.page-layout-2columns-right .column.main {
  width: 59.4%;
  float: left; }

.contact-index-index.page-layout-2columns-right .sidebar-main {
  width: 32.4%;
  float: right;
  padding-left: 0; }

/* Contact */
.contact-detail .section {
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 25px;
  margin-bottom: 35px; }

.contact-detail .section h2 {
  color: #46804b;
  font-size: 28px;
  margin: 0 0 18px; }

.contact-detail .section p {
  font-size: 16px;
  color: #222;
  margin: 0 0 14px;
  line-height: 26px; }

.contact-detail .section p a {
  text-decoration: underline; }

/* Sidebar */
.contact-sidebar .cta {
  position: relative;
  margin-bottom: 10px;
  height: 400px; }

.contact-sidebar .cta img {
  display: block;
  width: 100%; }

.contact-sidebar .cta .overlay {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  right: 10%;
  text-align: center;
  font-size: 16px; }

.contact-sidebar .cta {
  background: url("../images/photo-mask-sidebar_03.jpg") center center no-repeat;
  background-size: cover; }

/* Sidebar Overlay */
.contact-sidebar .cta .overlay h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  padding-bottom: 15px; }

.contact-sidebar .cta .overlay h3 a {
  color: #fff; }

/* Sidebar Overlay Content */
.contact-sidebar .cta .overlay p {
  color: #fff;
  margin: 0 0 18px; }

.contact-sidebar .cta .overlay p a {
  color: #fff; }

.contact-sidebar .cta .overlay p.sub {
  font-size: 23px;
  margin: 0 0 23px; }

.contact-sidebar .cta .overlay p:last-child {
  margin-bottom: 0; }

.contact-sidebar .cta table {
  text-align: left;
  color: #fff; }

.contact-sidebar .cta table tr td {
  border-bottom: 1px solid #fff;
  padding: 8px 0; }

.contact-sidebar .cta table tr:last-child td {
  border-bottom: 0; }

/* Sidebar Opening Hours */
.contact-sidebar .cta.cta-opening-hours {
  background-image: url("../images/photo-mask-sidebar_06.jpg"); }

.contact-sidebar .cta.cta-opening-hours .overlay h3 {
  padding-bottom: 0;
  margin: 0 0 13px;
  text-align: left; }

.contact-sidebar .cta.cta-opening-hours a.action.primary, .contact-sidebar .cta.cta-opening-hours #discount-form a.action, #discount-form .contact-sidebar .cta.cta-opening-hours a.action, .contact-sidebar .cta.cta-opening-hours .block-authentication .actions-toolbar > .primary a.action, .block-authentication .actions-toolbar > .primary .contact-sidebar .cta.cta-opening-hours a.action {
  float: left;
  font-size: 17px;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid #fff;
  padding: 15px 39px;
  text-transform: uppercase; }

/* Form */
.form.contact {
  padding: 0 0 50px; }

.form.contact h2 {
  color: #46804b;
  font-size: 28px;
  padding-bottom: 20px; }

.form.contact .group {
  display: flex;
  justify-content: space-between; }

.form.contact .group .control {
  max-width: 49%; }

.form.contact .control {
  width: 100%;
  margin-bottom: 20px; }

.form.contact .control .input-text {
  width: 100%;
  border: 1px solid #e5e5e5;
  height: 48px;
  box-sizing: border-box;
  padding: 0 15px;
  border-radius: 3px;
  font-size: 17px;
  font-family: "proxima-nova", Arial, sans-serif; }

.form.contact .control textarea.input-text {
  height: 108px;
  padding: 15px;
  font-family: "proxima-nova", Arial, sans-serif; }

.form.contact .fieldset {
  margin-bottom: 0; }

.form.contact .actions-toolbar {
  margin-left: 0; }

.form.contact .actions-toolbar .action.primary, .form.contact .actions-toolbar #discount-form .action, #discount-form .form.contact .actions-toolbar .action, .form.contact .block-authentication .actions-toolbar > .primary .action, .block-authentication .form.contact .actions-toolbar > .primary .action {
  width: 450px;
  height: 55px;
  box-sizing: border-box;
  font-size: 16px;
  text-transform: uppercase; }

.form.contact .control .input-text::-webkit-input-placeholder,
.form.contact .control textarea.input-text::-webkit-input-placeholder {
  color: #777; }

.form.contact .control .input-text::-moz-placeholder,
.form.contact .control textarea.input-text::-moz-placeholder {
  color: #777; }

.form.contact .control .input-text:-ms-input-placeholder,
.form.contact .control textarea.input-text:-ms-input-placeholder {
  color: #777; }

.form.contact .control .input-text:-moz-placeholder,
.form.contact .control textarea.input-text:-moz-placeholder {
  color: #777; }

@media (max-width: 1000px) {
  /* Sidebar */
  .contact-index-index.page-layout-2columns-right .sidebar-main,
  .contact-index-index.page-layout-2columns-right .column.main {
    width: 100%;
    float: none; }
  .contact-sidebar .cta {
    height: 300px; }
  .contact-sidebar .cta.cta-opening-hours {
    height: 380px; }
  /* Form */
  .form.contact {
    padding-bottom: 10px; } }

@media (max-width: 520px) {
  /* Sidebar */
  .contact-sidebar .cta {
    height: 370px; }
  .contact-sidebar .cta.cta-opening-hours {
    height: 440px; } }

@media (max-width: 400px) {
  /* Contact Form */
  .form.contact .group {
    display: block; }
  .form.contact .group .control {
    width: 100%;
    max-width: none; }
  .form.contact .actions-toolbar .action.primary, .form.contact .actions-toolbar #discount-form .action, #discount-form .form.contact .actions-toolbar .action, .form.contact .block-authentication .actions-toolbar > .primary .action, .block-authentication .form.contact .actions-toolbar > .primary .action {
    width: 100%; }
  .contact-sidebar .cta {
    height: 410px; }
  .contact-sidebar .cta .overlay {
    left: 6%;
    right: 6%; } }

.fieldset .fullname.field > .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.account-nav .content {
  padding: 30px 0; }

/* Navigation */
.account-nav .item.current a,
.account-nav .item.current strong {
  border-color: #e63329; }

.account-nav .item {
  font-size: 16px; }

.account-nav .item a {
  display: block;
  padding: 7px 18px; }

/* Content */
.account .page-main {
  padding: 30px 0; }

/* Newsletter */
.newsletter-manage-index .fieldset > .field.choice:before,
.newsletter-manage-index .fieldset > .field.no-label:before {
  display: none; }

.newsletter-manage-index .column:not(.sidebar-additional) form .actions-toolbar,
.newsletter-manage-index .column:not(.sidebar-main) form .actions-toolbar {
  margin-left: 0; }

/* Internal Account */
.account select,
.account input[type="email"],
.account input[type="password"],
.account input[type="text"] {
  height: 50px;
  font-size: 17px;
  border: 1px solid #e5e5e5; }

.account .actions-toolbar button.action.primary, .account .actions-toolbar #discount-form button.action, #discount-form .account .actions-toolbar button.action, .account .block-authentication .actions-toolbar > .primary button.action, .block-authentication .account .actions-toolbar > .primary button.action {
  height: 55px;
  min-width: 150px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px; }

/* Login */
.form-login input[type="password"],
.form-login input[type="text"],
.form-login input[type="email"] {
  height: 50px;
  font-size: 17px;
  border: 1px solid #e5e5e5; }

.form-login {
  /* Fields */
  /* Actions */ }
  .form-login .fieldset > .field {
    margin-bottom: 19px; }
  .form-login .field.note {
    margin-bottom: 30px; }
  .form-login .actions-toolbar {
    margin-left: 0 !important; }
  .form-login .actions-toolbar .action.login.primary, .form-login .actions-toolbar #discount-form .login.action, #discount-form .form-login .actions-toolbar .login.action, .form-login .block-authentication .actions-toolbar > .primary .login.action, .block-authentication .form-login .actions-toolbar > .primary .login.action {
    height: 55px;
    width: 150px;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px; }

/* New Customer - Action */
.block-new-customer .actions-toolbar .action.create.primary, .block-new-customer .actions-toolbar #discount-form .create.action, #discount-form .block-new-customer .actions-toolbar .create.action, .block-new-customer .block-authentication .actions-toolbar > .primary .create.action, .block-authentication .block-new-customer .actions-toolbar > .primary .create.action {
  height: 55px;
  width: 220px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 39px; }

/* Create Account */
.create.account {
  /* Inputs */
  /* Checkbox */
  /* Actions */ }
  .create.account input[type="text"],
  .create.account input[type="password"],
  .create.account input[type="email"] {
    height: 50px;
    font-size: 17px;
    border: 1px solid #e5e5e5; }
  .create.account .fieldset > .field.choice:before,
  .create.account .fieldset > .field.no-label:before {
    display: none; }
  .create.account .actions-toolbar {
    margin-left: 0 !important; }
  .create.account .actions-toolbar .action.submit.primary, .create.account .actions-toolbar #discount-form .submit.action, #discount-form .create.account .actions-toolbar .submit.action, .create.account .block-authentication .actions-toolbar > .primary .submit.action, .block-authentication .create.account .actions-toolbar > .primary .submit.action {
    height: 55px;
    width: 220px;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 30px; }

/* Reset Password */
.password.forget input[type="email"] {
  height: 50px;
  font-size: 17px;
  border: 1px solid #e5e5e5; }

.password.forget .actions-toolbar {
  margin-left: 0 !important; }

.password.forget .actions-toolbar .action.primary, .password.forget .actions-toolbar #discount-form .action, #discount-form .password.forget .actions-toolbar .action, .password.forget .block-authentication .actions-toolbar > .primary .action, .block-authentication .password.forget .actions-toolbar > .primary .action {
  height: 55px;
  width: 220px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px; }

/* Container */
.checkout-container {
  margin: 30px 0 20px; }

/* Progress Bar */
.opc-progress-bar-item > span {
  font-family: "proxima-nova", Arial, sans-serif; }

.opc-progress-bar-item._active > span:before,
.opc-progress-bar-item._active:before {
  background: #e63329; }

/* Titles */
.opc-wrapper .step-title {
  font-family: "proxima-nova", Arial, sans-serif; }

/* Form */
.opc-wrapper .fieldset > .field > .label,
.opc-wrapper .fieldset > .field.required > label {
  font-size: 16px;
  font-weight: 300; }

/* Delivery Options */
.table-checkout-shipping-method tbody td:nth-child(2n) {
  display: none; }

/* Fields */
.opc-wrapper .fieldset > .field .note:before, .opc-wrapper .fieldset > .fields > .field .note:before {
  margin-top: 0; }

.opc-wrapper .form-login .fieldset .note span {
  color: #666; }

.opc-wrapper select, .opc-wrapper input[type="text"], .opc-wrapper input[type="password"], .opc-wrapper input[type="url"], .opc-wrapper input[type="tel"],
.opc-wrapper input[type="search"], .opc-wrapper input[type="number"], .opc-wrapper input[type="datetime"], .opc-wrapper input[type="email"] {
  font-size: 16px;
  height: 48px;
  border: 1px solid #e5e5e5; }

/* Mini Cart */
.opc-block-summary .product-item .price {
  display: none; }

/* Information */
.shipping-information-title span {
  font-family: "proxima-nova", Arial, sans-serif;
  font-size: 21px; }

/* Discount */
#discount-form {
  display: none !important; }

.checkout-payment-method .payment-option-title .action-toggle {
  color: #222;
  font-size: 16px;
  font-weight: 300; }

/* Payment Method */
.payment-method {
  font-weight: 300;
  font-size: 16px; }

/* Summary */
.opc-block-summary {
  padding-bottom: 30px; }

.opc-block-summary > .title {
  font-family: "proxima-nova", Arial, sans-serif; }

.opc-block-summary .items-in-cart > .title {
  padding-top: 16px;
  padding-bottom: 16px; }

.opc-block-summary .items-in-cart > .title:after {
  top: 18px; }

.opc-block-summary .table-totals {
  display: none !important; }

.opc-block-summary .table-totals .grand .amount strong {
  font-weight: 400; }

/* Actions */
.checkout-shipping-method .actions-toolbar .action.primary, .checkout-shipping-method .actions-toolbar #discount-form .action, #discount-form .checkout-shipping-method .actions-toolbar .action, .checkout-shipping-method .block-authentication .actions-toolbar > .primary .action, .block-authentication .checkout-shipping-method .actions-toolbar > .primary .action {
  min-width: 100px; }

/* Authentication */
.authentication-dropdown {
  margin-top: 20px; }

.authentication-dropdown label.label {
  padding: 0 0 10px !important;
  font-size: 16px !important;
  text-transform: uppercase;
  font-weight: 300 !important; }

.authentication-dropdown .input-text {
  height: 48px;
  font-size: 15px; }

.block-authentication .actions-toolbar > .primary .action {
  color: #fff !important; }

body._has-modal-custom .modal-custom-overlay {
  display: none !important; }

.checkout-container .authentication-wrapper {
  margin-top: 0; }

.checkout-container .authentication-wrapper .action {
  color: #222; }

/* Success */
.checkout-success {
  font-size: 18px; }

.checkout-onepage-success #registration {
  display: none !important; }

.checkout-onepage-success .actions-toolbar {
  padding-top: 16px; }

.checkout-onepage-success .actions-toolbar .primary a.action {
  font-size: 16px;
  padding: 15px 25px; }

/* Footer Content */
.footer.content {
  border: 0; }

/* Footer */
.page-footer {
  background: #ebebeb; }

/* Footer Top Bar */
.footer-top-bar-container {
  background: #e63329; }

.footer-top-bar-container .footer-top {
  max-width: 1540px;
  margin: 0 auto;
  width: 96%; }

@media (min-width: 1500px) {
  /* Footer Bar */
  .footer-top-bar-container .footer-top ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between; }
  .footer-top-bar-container .footer-top ul li {
    margin: 0;
    color: #fff;
    font-size: 18px;
    padding: 30px 13px 30px 55px;
    background: url("../images/svg/tick-white.svg") center left no-repeat;
    background-size: 40px 40px; } }

@media (max-width: 1500px) {
  /* Footer Bar */
  .footer-top-bar-container .footer-top ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .footer-top-bar-container .footer-top ul li {
    margin: 0;
    padding: 0;
    text-align: center; }
  .footer-top-bar-container .footer-top ul li span {
    display: inline-block;
    vertical-align: top;
    color: #fff;
    font-size: 18px;
    padding: 30px 13px 30px 55px;
    background: url("../images/svg/tick-white.svg") center left no-repeat;
    background-size: 40px 40px; } }

/* Footer */
.footer-container .footer {
  max-width: 1540px;
  margin: 0 auto;
  min-height: 200px;
  width: 96%; }

.footer-container .footer .footer-cols {
  display: flex;
  justify-content: space-between; }

.footer-container .footer .footer-cols .col {
  padding: 70px 0 40px;
  font-size: 18px; }

.footer-container .footer .footer-cols .col ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.footer-container .footer .footer-cols .col ul li {
  font-weight: 300; }

.footer-container .footer .footer-cols .col .social {
  padding-top: 15px; }

col col-logo .footer-container .footer .footer-cols .col .social img {
  display: inline-block;
  vertical-align: top;
  padding-right: 5px; }

.footer-container .footer .footer-cols .col a:hover {
  text-decoration: none; }

.footer-container .footer .footer-cols .col p {
  line-height: 30px; }

.footer-container .footer .footer-cols .col p strong {
  color: #e63329; }

.social .social-link {
  background: #e63329;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  width: 37px;
  height: 36px;
  border-radius: 150px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 3px;
  margin-right: 5px; }

.social .social-link:hover {
  background: #c82d23; }

/* Footer Newsletter */
.footer-newsletter {
  margin-top: 25px; }

.footer-newsletter #newsletter-form {
  position: relative; }

.footer-newsletter #newsletter-form #newsletter-form-email {
  width: 345px;
  height: 48px;
  border: 0;
  box-sizing: border-box;
  padding-right: 60px;
  font-size: 14px;
  padding-left: 15px; }

.footer-newsletter #newsletter-form #newsletter-form-submit {
  position: absolute;
  top: 0;
  right: -9px; }

#newsletter-form-success {
  background: #3d7041;
  color: #fff;
  padding: 10px 20px; }

/* Bottom */
.footer-bottom-container {
  padding-bottom: 20px; }

.footer-bottom-container .footer-bottom {
  max-width: 1540px;
  margin: 0 auto;
  padding: 0;
  width: 96%; }

/* Copyright */
.footer-bottom-container .footer-bottom .copyright {
  display: inline-block;
  vertical-align: top;
  padding-right: 25px; }

.footer-bottom-container .footer-bottom .address {
  display: inline-block;
  vertical-align: top;
  padding-right: 25px; }

.footer-bottom-container .footer-bottom .company-number {
  display: inline-block;
  vertical-align: top; }

.footer-bottom-container .footer-bottom .attrib {
  float: right;
  display: inline-block;
  vertical-align: top; }

@media (max-width: 1370px) {
  /* Footer Bottom */
  .footer-bottom-container .footer-bottom .address {
    display: block; } }

@media (max-width: 1250px) {
  /* Footer Cols */
  .footer-cols .col.col-logo {
    width: 20%; }
  /* Newsletter */
  .footer-newsletter #newsletter-form #newsletter-form-email {
    width: 100%; } }

@media (max-width: 950px) {
  /* Footer */
  .footer-cols .col.col-logo {
    display: none; } }

@media (max-width: 768px) {
  /* Footer */
  .footer-container .footer .footer-cols {
    display: block; }
  .footer-container .footer .footer-cols .col {
    width: 100%;
    clear: both;
    padding: 30px 0 0; }
  .footer-container .footer .footer-cols .col.col-newsletter {
    padding-top: 10px;
    padding-bottom: 40px; }
  .footer-newsletter #newsletter-form #newsletter-form-submit {
    right: 0; }
  .footer-bottom-container .footer-bottom .copyright {
    padding-right: 0; }
  .footer-bottom-container .footer-bottom .copyright .detail {
    display: block; }
  .footer-bottom-container .footer-bottom .copyright .sep {
    display: none; }
  .footer-bottom-container .footer-bottom .copyright a {
    margin: 0 5px; }
  /* Centre content */
  .footer-container .footer {
    text-align: center; }
  .footer-bottom-container .footer-bottom .address {
    text-align: center;
    display: block;
    padding-right: 0; }
  .footer-bottom-container .footer-bottom .copyright {
    text-align: center;
    display: block; }
  .footer-bottom-container .footer-bottom .company-number {
    text-align: center;
    display: block; }
  .footer-bottom-container .footer-bottom .attrib {
    float: none;
    text-align: center;
    display: block; }
  /* Fix */
  .footer-bottom-container .footer-bottom .copyright .detail {
    display: none; }
  .footer-bottom-container .footer-bottom .additional-copyright {
    display: block !important;
    text-align: center; } }

.wishlist-index-index .page-title-wrapper {
  padding-bottom: 15px; }

#wishlist-view-form {
  /* Toolbar */
  /* Product List */ }
  #wishlist-view-form .actions-toolbar {
    background: #f5f5f5;
    padding: 20px;
    margin-top: 20px; }
  #wishlist-view-form .wishlist-grid ol > li {
    width: 100%;
    float: none;
    display: block;
    clear: both;
    overflow: hidden;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 10px; }
  #wishlist-view-form .wishlist-grid ol > li:last-child {
    border-bottom: 0; }
  #wishlist-view-form .wishlist-grid li .product-item-name a {
    color: #aaa; }
  #wishlist-view-form .wishlist-grid li .product-item-name {
    margin: 0 25px 0 0;
    width: 50%; }
  #wishlist-view-form .wishlist-grid li .product-item-name,
  #wishlist-view-form .wishlist-grid li .product-item-actions,
  #wishlist-view-form .wishlist-grid li .box-tocart,
  #wishlist-view-form .wishlist-grid li .fieldset,
  #wishlist-view-form .wishlist-grid li .product-options,
  #wishlist-view-form .wishlist-grid li .field.qty,
  #wishlist-view-form .wishlist-grid li .product-item-inner {
    display: inline-block;
    vertical-align: top; }
  #wishlist-view-form .wishlist-grid li .product-item-inner {
    float: right; }
  #wishlist-view-form .wishlist-grid li .product-item-info {
    width: auto;
    display: block; }
  #wishlist-view-form .wishlist-grid li input[type="number"] {
    border: 1px solid #eee; }
  #wishlist-view-form .wishlist-grid li .qty {
    margin: 0; }
  #wishlist-view-form .wishlist-grid li .fieldset > .field:not(.choice) > .control {
    width: 64%; }
  #wishlist-view-form .wishlist-grid li .product-options ul {
    list-style: none;
    margin: 0;
    padding: 6px 0 0; }
  #wishlist-view-form .wishlist-grid li .product-options ul li {
    padding: 0;
    margin: 0; }
  #wishlist-view-form .wishlist-grid li .product-item-actions .action.primary, #wishlist-view-form .wishlist-grid li .product-item-actions #discount-form .action, #discount-form #wishlist-view-form .wishlist-grid li .product-item-actions .action, #wishlist-view-form .wishlist-grid li .product-item-actions .block-authentication .actions-toolbar > .primary .action, .block-authentication .actions-toolbar > .primary #wishlist-view-form .wishlist-grid li .product-item-actions .action {
    text-transform: uppercase; }

.wishlist-index-index.account .fieldset > .field:not(.choice) > .label {
  padding-top: 6px;
  text-transform: uppercase; }

/* Action Toolbar Buttons */
.wishlist-index-index .actions-toolbar .action.primary, .wishlist-index-index .actions-toolbar #discount-form .action, #discount-form .wishlist-index-index .actions-toolbar .action, .wishlist-index-index .block-authentication .actions-toolbar > .primary .action, .block-authentication .wishlist-index-index .actions-toolbar > .primary .action {
  background: #fff;
  color: #46804b;
  text-transform: uppercase;
  border: 0;
  font-weight: 400;
  padding: 15px 25px;
  font-size: 16px;
  margin-right: 10px; }

.wishlist-index-index .actions-toolbar .action.primary:active, .wishlist-index-index .actions-toolbar #discount-form .action:active, #discount-form .wishlist-index-index .actions-toolbar .action:active, .wishlist-index-index .block-authentication .actions-toolbar > .primary .action:active, .block-authentication .wishlist-index-index .actions-toolbar > .primary .action:active,
.wishlist-index-index .actions-toolbar .action.primary:hover,
.wishlist-index-index .actions-toolbar #discount-form .action:hover,
#discount-form .wishlist-index-index .actions-toolbar .action:hover,
.wishlist-index-index .block-authentication .actions-toolbar > .primary .action:hover,
.block-authentication .wishlist-index-index .actions-toolbar > .primary .action:hover {
  background: #46804b;
  color: #fff; }

/* Back Button */
.wishlist-index-index .actions-toolbar .secondary a.back.action {
  text-transform: uppercase;
  color: #46804b; }

@media (max-width: 1180px) {
  /* List */
  #wishlist-view-form .wishlist-grid li .product-item-name {
    width: 35%; }
  #wishlist-view-form .wishlist-grid li input[type="number"] {
    max-width: 60px; }
  /* Qty */
  .wishlist-grid li .fieldset > .field:not(.choice) > .label {
    display: none; } }

@media (max-width: 768px) {
  /* Account */
  .account .page.messages {
    margin: 0; }
  /* List */
  .product-item-actions {
    margin-left: 10px; }
  #wishlist-view-form .wishlist-grid li .product-item-name {
    width: 34%; }
  #wishlist-view-form .wishlist-grid li .qty label {
    display: none; } }

@media (max-width: 550px) {
  /* List */
  .wishlist-grid .product-item-name {
    display: block;
    clear: both;
    float: left;
    margin: 0;
    padding-bottom: 10px; }
  #wishlist-view-form .wishlist-grid ol > li {
    padding-bottom: 20px;
    margin-bottom: 20px; }
  #wishlist-view-form .wishlist-grid li {
    border-bottom: 1px solid #eee;
    margin-bottom: 18px; }
  #wishlist-view-form .wishlist-grid li:last-child {
    border-bottom: 0; }
  #wishlist-view-form .wishlist-grid li .product-item-inner {
    float: left;
    overflow: hidden;
    clear: both; }
  #wishlist-view-form .wishlist-grid li .product-options {
    float: left;
    clear: both;
    width: 100%;
    padding-bottom: 15px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.gallery-arrow {
  width: 14px;
  height: 8px;
  display: block;
  margin: 0 auto 20px;
  cursor: pointer; }

.gallery-arrow.arrow-up {
  transform: rotate(180deg);
  display: block;
  margin: 0 auto 20px; }

.ie-fixes, .ie9, .ie10 {
  /* Navigation */
  /* Banner CTA */
  /* Category CTA */
  /* Footer Bar */
  /* Testimonials */
  /* Cart Heading */
  /* Cols */ }
  .ie-fixes .new-navigation ul, .ie9 .new-navigation ul, .ie10 .new-navigation ul {
    display: block; }
  .ie-fixes .new-navigation ul li, .ie9 .new-navigation ul li, .ie10 .new-navigation ul li {
    display: block;
    float: left;
    margin-right: 2.6%; }
  .ie-fixes .cta-container, .ie9 .cta-container, .ie10 .cta-container {
    display: block;
    overflow: hidden; }
  .ie-fixes .cta-container .cta, .ie9 .cta-container .cta, .ie10 .cta-container .cta {
    float: left;
    display: block; }
  .ie-fixes .category-cta-container .category-cta, .ie9 .category-cta-container .category-cta, .ie10 .category-cta-container .category-cta {
    overflow: hidden; }
  .ie-fixes .category-cta-container .category-cta .cta, .ie9 .category-cta-container .category-cta .cta, .ie10 .category-cta-container .category-cta .cta {
    float: left;
    margin-right: 0.7%; }
  .ie-fixes .category-cta-container .category-cta .cta:nth-child(3n), .ie9 .category-cta-container .category-cta .cta:nth-child(3n), .ie10 .category-cta-container .category-cta .cta:nth-child(3n) {
    margin-right: 0; }
  .ie-fixes .footer-top-bar-container .footer-top ul, .ie9 .footer-top-bar-container .footer-top ul, .ie10 .footer-top-bar-container .footer-top ul {
    overflow: hidden; }
  .ie-fixes .footer-top-bar-container .footer-top ul li, .ie9 .footer-top-bar-container .footer-top ul li, .ie10 .footer-top-bar-container .footer-top ul li {
    float: left;
    margin-right: 2%; }
  .ie-fixes .testimonal-slider .testimonial, .ie9 .testimonal-slider .testimonial, .ie10 .testimonal-slider .testimonial {
    width: 100%; }
  .ie-fixes .cart-heading ul li, .ie9 .cart-heading ul li, .ie10 .cart-heading ul li {
    margin-right: 20px; }
  .ie-fixes .footer-container .footer .footer-cols, .ie9 .footer-container .footer .footer-cols, .ie10 .footer-container .footer .footer-cols {
    overflow: hidden; }
  .ie-fixes .footer-container .footer .footer-cols .col, .ie9 .footer-container .footer .footer-cols .col, .ie10 .footer-container .footer .footer-cols .col {
    width: 22%;
    margin-right: 3%;
    float: left; }


/* Nav container */
.nav-sections-new     { background: #eee; height: 50px; margin-bottom: 20px; }
.nav-sections-new .nav-sections-new-items    { max-width: 1540px; margin: 0 auto; width: 98%; }
.nav-sections-new .nav-sections-new-item-title    { display: none; }

/* Nav menu */
.new-navigation ul   { margin: 0; padding: 0; list-style: none; display: flex; justify-content: space-between }
.new-navigation ul li.level-top   { margin: 0; padding: 0; }
.new-navigation ul li.level-top a.level-top   { padding: 15px 0 16px; display: inline-block; vertical-align: top; font-weight: 400; font-size: 16px; text-decoration: none; }
.new-navigation ul li.level-top.active a.level-top   { color: $primary; }

/* Subnav */
.new-navigation ul ul    { display: none !important }

/* Old Toggle */
.action.nav-toggle   { display: none !important }

@media (max-width: 1040px) {
  /* Menu */
  .new-navigation ul     { display: none; }
}

@media (min-width: 769px) and (max-width: 1040px) {
  /* Tablet Nav Toggle */
  .tablet-menu-toggle   { display: inline-block !important; vertical-align: top; font-size: 16px; text-transform: uppercase; padding: 15px 10px 12px; font-weight: 400; text-decoration: none; }
  .tablet-menu-toggle:hover   { text-decoration: none; }
  /* Tablet Nav */
  .new-navigation ul.open     { position: absolute; top: 50px; left: 0; right: 0; z-index: 2500; width: 100%; background: #fff; padding: 20px 0; display: block !important; box-sizing: border-box }
  .new-navigation ul.open li.level-top a.level-top    { padding: 8px 20px; display: block; text-transform: uppercase; }
  .new-navigation ul.open li.level-top a.level-top:hover,
  .new-navigation ul.open li.level-top a.level-top:active    { background: $primary; color: #fff; }
  .nav-sections-new     { position: relative; }
}

@media (max-width: 768px) {
  /* Mobile Navigation - Toggle */
  .mobile-nav-toggle  { display: block !important; position: absolute; right: 15px; top: 35px; }
  .mobile-nav-toggle .mobile-nav-toggle-link  { display: inline-block; width: 30px; height: 30px; background: url('../images/svg/burger.svg') center center no-repeat; cursor: pointer; transition: background 0.2s }
  .mobile-nav-toggle .mobile-nav-toggle-link.open  { background-image: url('../images/svg/close.svg') }
  /* Mobile Navigation */
  .nav-sections-new   { position: relative; }
  .new-navigation ul.open  { position: absolute; top: 50px; left: 0; right: 0; width: 100%; background: #fff; z-index: 2500; display: block; padding: 15px 0; text-align: left; box-shadow: 0px 18px 25px -15px rgba(0, 0, 0, 0.3); }
  .new-navigation ul.open li.level-top    {  }
  .new-navigation ul.open li.level-top a.level-top  { color: $primary; display: block; padding: 8px 20px; }
  .new-navigation ul.open li.level-top:active    { background: $primary; }
  .new-navigation ul.open li.level-top:active a    { color: #fff; }
  /* Phone number */
  .mobile-number    { color: #222; display: block !important; margin: 0; font-weight: 400; text-transform: uppercase; padding: 15px 0 0 0.5%; font-size: 16px; }
}
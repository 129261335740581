
/* Container */
.banner-container { max-width: 1540px; width: 96%; margin: 0 auto 11px; padding-left: 10px; padding-right: 10px; }
.banner-container .banner { position: relative; }

/* Main Image */
.banner-container .banner .main-image-group { }
.banner-container .banner .main-image-group .main-image { display: block; }

/* Mask */
.banner-container .banner .mask { position: absolute; left: 0; right: 0; top: 0; bottom: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.3); }

/* Overlay Container */
.banner-container .banner .overlay-container  { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
.banner-container .banner .overlay-container .arrow   { position: absolute; top: 50%; transform: translateY(-50%); z-index: 600; cursor: pointer; }
.banner-container .banner .overlay-container .arrow.arrow-left { left: 25px; }
.banner-container .banner .overlay-container .arrow.arrow-right { right: 25px; }

/* Overlay */
.banner-container .banner .overlay-container .overlay { position: absolute; background: transparent; top: 49%; transform: translateY(-50%); left: 0; right: 0; padding: 45px 30px 45px 70px; width: 100%; max-width: 824px; text-align: center; margin: 0 auto; }
.banner-container .banner .overlay-container .overlay .title { color: #fff; font-size: 48px; margin: 0; }
.banner-container .banner .overlay-container .overlay .under-title { color: #fff; }
.banner-container .banner .overlay-container .overlay .link { color: #fff; }
.banner-container .banner .overlay-container .overlay .link a { color: #fff; display: inline-block; vertical-align: top; background: $secondary; padding: 15px 20px; text-transform: uppercase; text-align: center; width: 316px; height: 65px; box-sizing: border-box; line-height: 34px; text-decoration: none; font-size: 17px; border-radius: 3px }
.banner-container .banner .overlay-container .overlay .link a:hover { background: darken($secondary, 5%); }

/* Pager */
.banner-container .cycle-pager { position: absolute; bottom: 6px; left: 0; right: 0; text-align: center; margin: 0 auto; z-index: 600; }
.banner-container .cycle-pager span { display: inline-block; vertical-align: top; width: 10px; height: 10px; background: #fff; margin: 0 5px; text-indent: -999em; border-radius: 25px; cursor: pointer; }
.banner-container .cycle-pager span.cycle-pager-active { background: $primary; }

/* Banner - Home */
.banner-container.banner-home .banner .overlay-container .overlay     { background: rgba(0, 0, 0, 0.4); right: auto; text-align: left; }
.banner-container.banner-home .banner .overlay-container .overlay .title    { margin-bottom: 30px; }
.banner-container.banner-home .banner .mask     { background: rgba(0,0,0,0.1); }

@media (max-width: 1400px) {
  /* Overlay */
  .banner-container .banner .overlay-container .overlay     { padding: 25px 30px 32px 70px; }
}

@media (max-width: 1250px) {
  /* Pager */
  .cycle-pager      { display: none; }
}

@media (max-width: 1070px) {
  /* Overlay */
  .banner-container .banner .overlay-container .overlay   { padding: 15px 30px 17px 70px; box-sizing: border-box }
  .banner-container .banner .overlay-container .overlay .title    { font-size: 36px; }
  .banner-container .banner .overlay-container .overlay .link a     { line-height: 34px; height: auto; min-height: 52px; padding: 10px 20px; }
  .banner-container.banner-home .banner .overlay-container .overlay .title    { margin-bottom: 15px; }
}

@media (max-width: 768px) {
  /* Mobile Banner */
  .banner-container   { height: 250px; overflow: hidden; width: 100%; box-sizing: border-box; padding-left: 10px; padding-right: 10px; overflow: hidden; }
  .banner-container .banner   { right: 0; }
  .banner-container .banner .main-image-group   { width: 100%; overflow: hidden; }
  .banner-container .banner .main-image-group .main-image     { max-width: none; }
  .banner-container .banner .overlay-container .arrow     { display: none; }
  .banner-container .banner .overlay-container .overlay     { padding: 15px 30px 17px 30px }

  /* Home Banner */
  .banner-container.banner-home   { height: 400px; width: 100%; box-sizing: border-box; padding-left: 10px; padding-right: 10px; overflow: hidden; }
  .banner-container.banner-home .main-image-group  { width: 100%; overflow: hidden; }
  .banner-container.banner-home .banner .overlay-container .overlay   { text-align: center; right: 0; }
}

@media (max-width: 550px) {
   /* Overlay */
  .banner-container .banner .overlay-container .overlay   { padding: 35px 20px 40px 20px; }
  .banner-container .banner .overlay-container .overlay .link a     { width: 100%; box-sizing: border-box }
  .banner-container.banner-home .banner .overlay-container .overlay .title    { margin-bottom: 35px; }
}
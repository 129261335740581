.fieldset .fullname.field > .label {
    @include lib-visually-hidden();
}

.account-nav .content     { padding: 30px 0; }

/* Navigation */
.account-nav .item.current a,
.account-nav .item.current strong   { border-color: $primary }
.account-nav .item  { font-size: 16px; }
.account-nav .item a  { display: block; padding: 7px 18px; }

/* Content */
.account .page-main   { padding: 30px 0; }

/* Newsletter */
.newsletter-manage-index .fieldset > .field.choice:before,
.newsletter-manage-index .fieldset > .field.no-label:before    { display: none; }
.newsletter-manage-index .column:not(.sidebar-additional) form .actions-toolbar,
.newsletter-manage-index .column:not(.sidebar-main) form .actions-toolbar        { margin-left: 0; }

/* Internal Account */
.account select,
.account input[type="email"],
.account input[type="password"],
.account input[type="text"]     { height: 50px; font-size: 17px; border: 1px solid #e5e5e5 }

.account .actions-toolbar button.action.primary   { height: 55px; min-width: 150px; text-align: center; text-transform: uppercase; font-size: 16px; }

/* Login */
.form-login input[type="password"],
.form-login input[type="text"],
.form-login input[type="email"]     { height: 50px; font-size: 17px; border: 1px solid #e5e5e5 }
.form-login {
  /* Fields */
  .fieldset > .field    { margin-bottom: 19px; }
  .field.note   { margin-bottom: 30px; }
  /* Actions */
  .actions-toolbar    { margin-left: 0 !important; }
  .actions-toolbar .action.login.primary   { height: 55px; width: 150px; text-align: center; text-transform: uppercase; font-size: 16px; }
}

/* New Customer - Action */
.block-new-customer .actions-toolbar .action.create.primary  { height: 55px; width: 220px; text-align: center; text-transform: uppercase; font-size: 16px; line-height: 39px }

/* Create Account */
.create.account {

  /* Inputs */
  input[type="text"],
  input[type="password"],
  input[type="email"]       { height: 50px; font-size: 17px; border: 1px solid #e5e5e5 }
  /* Checkbox */
  .fieldset > .field.choice:before,
  .fieldset > .field.no-label:before    { display: none; }
  /* Actions */
  .actions-toolbar  { margin-left: 0 !important }
  .actions-toolbar .action.submit.primary  { height: 55px; width: 220px; text-align: center; text-transform: uppercase; font-size: 16px; margin-bottom: 30px; }
}

/* Reset Password */
.password.forget {
  input[type="email"]     { height: 50px; font-size: 17px; border: 1px solid #e5e5e5 }
  .actions-toolbar  { margin-left: 0 !important }
  .actions-toolbar .action.primary { height: 55px; width: 220px; text-align: center; text-transform: uppercase; font-size: 16px; }
 }
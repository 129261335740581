
/* Content */
.cms-index-index  {
  .content-container  { padding: 80px 545px 90px 40px; background: #ebebeb url('../images/bg-content-lorry.png') top right no-repeat; margin-bottom: 10px; }
  .content-container h1.page-title  { font-size: 38px; color: $secondary; padding-bottom: 15px; margin-bottom: 0; font-weight: 400; }
  .content-container p    { font-size: 20px; font-weight: 300; }
  .content-container a.action.primary   { display: inline-block; text-decoration: none; padding: 18px 35px; font-size: 17px; margin-top: 20px; }
}

/* Opening Hours / Telephone CTA */
.contact-cta-container { margin-bottom: 48px; }

/* Opening Hours */
.contact-cta-container .contact-cta.cta-opening-hours { background: url('../images/cta_contact_03.png') top left no-repeat; width: 49.676%; height: 250px; float: left; position: relative; color: #fff; }
.contact-cta-container .contact-cta.cta-opening-hours .overlay { position: absolute; top: 0; left: 0; right: 0; bottom: 0; box-sizing: border-box; padding: 25px 35px; }
.contact-cta-container .contact-cta.cta-opening-hours h2 { color: #fff; font-size: 30px; float: left; width: 40%; padding-bottom: 25px; line-height: 36px; margin: 17px 0 12px; }
.contact-cta-container .contact-cta.cta-opening-hours .action.primary { float: left; clear: left; text-transform: uppercase; background: #1b4820; border: 2px solid #fff; padding: 16px 38px; font-size: 17px; }
.contact-cta-container .contact-cta.cta-opening-hours .action.primary:hover { }
.contact-cta-container .contact-cta.cta-opening-hours table { padding: 0; color: #fff; width: 50%; float: right; font-size: 16px; }
.contact-cta-container .contact-cta.cta-opening-hours table tr { }
.contact-cta-container .contact-cta.cta-opening-hours table tr td { padding: 7px 0 7px; border-bottom: 1px solid #fff }
.contact-cta-container .contact-cta.cta-opening-hours table tr:last-child td { border-bottom: 0 }

/* Telephone */
.contact-cta-container .contact-cta.cta-phone { background: url('../images/cta_contact_05.png') top left no-repeat; width: 49.676%; height: 250px; float: right; position: relative; color: #fff; text-align: center; }
.contact-cta-container .contact-cta.cta-phone .overlay  { position: absolute; top: 50%; transform: translateY(-50%); left: 15%; right: 15%; bottom: 0; }
.contact-cta-container .contact-cta.cta-phone h2 { font-size: 30px; padding-bottom: 20px; margin-top: 0; padding-top: 0; }
.contact-cta-container .contact-cta.cta-phone p { font-size: 23px; margin: 0; }

/* Testimonials */
.testimonials-container { padding-bottom: 80px; }
.testimonials { width: 49.7%; float: left; text-align: center; border-right: 1px solid #c8c8c8; }
.testimonials h2 { font-weight: 600; padding-bottom: 18px; font-size: 21px; }
.testimonials p { margin: 0 0 16px; line-height: 27px; font-size: 16px; font-style: italic; }
.testimonials p.author { font-style: normal; }
.testimonials p strong { font-style: normal; }

.testimonal-slider    { position: relative; }
.testimonal-slider .quote-open   { position: absolute; top: 0; left: 30px; }
.testimonal-slider .quote-close   { position: absolute; right: 30px; bottom: 0 }
.testimonal-slider .testimonial    { max-width: 385px; margin: 0 auto; right: 0; }
.testimonal-slider .testimonial-pager   { list-style: none; margin: 0; padding: 20px 0 0 0; }
.testimonal-slider .testimonial-pager span  { display: inline-block; vertical-align: top; width: 10px; height: 10px; background: #e6e7e8; border-radius: 35px; text-indent: -999em; margin: 0 5px; cursor: pointer; }
.testimonal-slider .testimonial-pager span.cycle-pager-active   { background: #e63329; }

/* Testimonials Info */
.testimonials-container .testiminals-info { width: 45.7%; float: right; }
.testimonials-container .testiminals-info h2 { font-size: 21px; color: #46804b; font-weight: 600; padding-bottom: 18px; }
.testimonials-container .testiminals-info p { font-size: 16px; line-height: 25px }

@media (max-width: 1250px) {
  /* Testimonials */
  .testimonal-slider .quote-open,
  .testimonal-slider .quote-close     { width: 40px; height: 40px; }
}

@media (max-width: 1100px) {
  /* Content */
  .cms-index-index .content-container     { background-position: 130% 50%; padding-right: 415px; }
}

@media (max-width: 860px) {
  /* Content */
  .cms-index-index .content-container     { background-image: none; padding: 40px 25px; }
}


@media (min-width:768px ) and (max-width: 1200px) {
  /* Opening Hours CTA */
  .contact-cta-container .contact-cta.cta-opening-hours   { height: 450px; background-size: cover; display: flex; flex-wrap: wrap; }
  .contact-cta-container .contact-cta.cta-opening-hours .overlay   { display: flex; flex-wrap: wrap; }
  .contact-cta-container .contact-cta.cta-opening-hours h2    { order: 1; width: 100%; padding-bottom: 0; }
  .contact-cta-container .contact-cta.cta-opening-hours table     { order: 2; width: 100%; }
  .contact-cta-container .contact-cta.cta-opening-hours .action.primary     { order: 3; height: 50px !important }
  .contact-cta-container .contact-cta.cta-phone   { height: 450px; background-size: cover }

}

@media (max-width: 768px) {
  /* Contact CTA */
  .contact-cta-container .contact-cta.cta-phone,
  .contact-cta-container .contact-cta.cta-opening-hours   { width: 100%; margin-bottom: 10px; }
  .contact-cta-container .contact-cta.cta-phone     { height: 250px; background-size: cover }
  .contact-cta-container .contact-cta.cta-phone .overlay    { bottom: auto; }
  /* Testimonials */
  .testimonials   { width: 100%; margin-bottom: 45px; border-right: 0; border-bottom: 1px solid #eee; padding-bottom: 45px;  }
  .testimonials-container .testiminals-info     { width: 100%;  }
  .testimonials-container     { padding-bottom: 20px; }
  .testimonials h2  { padding: 0 57px 18px; }
  /* Quotes */
  .testimonal-slider .quote-open  { left: 5px; }
  .testimonal-slider .quote-close { right: 5px; }
}

@media (max-width: 580px) {
  /* Contact CTA */
  .contact-cta-container .contact-cta.cta-opening-hours     { height: 430px; background-size: cover; }
  .contact-cta-container .contact-cta.cta-opening-hours .overlay    { display: flex; flex-wrap: wrap; align-items: flex-start; padding: 25px 5%; }
  .contact-cta-container .contact-cta.cta-opening-hours h2    { width: 100%; order: 1; padding-bottom: 0; margin-bottom: 0; }
  .contact-cta-container .contact-cta.cta-opening-hours table     { width: 100%; order: 2 }
  .contact-cta-container .contact-cta.cta-opening-hours .action.primary     { order: 3; padding: 16px 38px 16px; }
}

@media (max-width: 450px) {
  /* Contact CTA */
  .contact-cta-container .contact-cta.cta-phone .overlay    { left: 5%; right: 5%; }
}

@media (max-width: 350px) {
  /* Contact CTA */
  .contact-cta-container .contact-cta.cta-opening-hours .overlay  { padding: 25px 20px; }
}
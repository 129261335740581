.banner-template-a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #ebebeb;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        background-color: #000;
        opacity: 0.3;
    }
}

.banner-template-a__overlay {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 20px;
}

.banner-template-a__heading {
    margin: 0;
    font-size: 38px;

    @media (min-width: 1024px) {
        font-size: 48px;
    }
}

.banner-template-a__subtitle {
    margin: 10px 0 0;
    font-size: 18px;

    @media (min-width: 1024px) {
        font-size: 28px;
    }
}


/* CTA */
.cta-container { display: flex; justify-content: space-between; margin-bottom: 40px; }
.cta-container .cta { position: relative; margin-right: 11px; }
.cta-container .cta .background { display: block; }
.cta-container .cta .mask { position: absolute; left: 0; right: 0; top: 0; bottom: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.3); transition: background 0.2s }
.cta-container .cta:hover .mask { background: rgba(0, 0, 0, 0.4); }
.cta-container .cta:nth-child(3n)  { margin-right: 0; }

/* Overlay */
.cta-container .cta .overlay { position: absolute; top: 50%; transform: translateY(-50%); left: 0; right: 0; text-align: center; }
.cta-container .cta .overlay h2 { color: #fff; font-size: 30px; }
.cta-container .cta .overlay p { }

/* Action */
.cta-container .cta .action { }
.cta-container .cta .action:hover { }
.cta-container .cta .action img { }

@media (max-width: 950px) {
  .cta-container .cta .overlay h2   { font-size: 24px; padding: 0 14px; }
}

@media (max-width: 768px) {
  /* Banner CTA */
  .cta-container  { display: block; margin-bottom: 30px; }
  .cta-container .cta     { height: 130px; overflow: hidden; margin: 0 0 10px 0; }
  .cta-container .cta .background     { width: 100%; }
}

/* Product - Share */
.share-buttons-group  { position: absolute; top: 28px; right: 0; }

/* Share Buttons */
.share-buttons { width: 40px; height: 50px; position: absolute; top: 0px; right: 0px; }
.share-buttons:before { content: 'Share'; top: 6px; left: -55px; position: absolute; font-size: 14px; text-transform: uppercase; color: #222; }
.share-button { width: 40px; height: 40px; line-height: 40px; text-align: center; border-radius: 4px; background: #fff; font-size: 1.25em; color: #fff; cursor: pointer; transition: margin-top 0.4s; }
.share-buttons:hover .share-button:first-child,
.share-button:hover { background: #ff5c3e; }
.share-buttons .expandable { opacity: 0; visibility: hidden; transition: 0.4s; padding-top: 10px; }
.share-buttons:hover .expandable, .share-buttons.active .expandable { opacity: 1; visibility: visible; }
.share-buttons .expandable .share-button { margin-top: -15px; margin-bottom: 10px; }
.share-buttons:hover .expandable .share-button, .share-buttons.active .expandable .share-button { margin-top: 0; }

.share-button.expand { background: #fff !important; }
.share-button.expand:hover { background: #fff !important; }

.share-button.pinterest { background: #cc1e27; }
.share-button.pinterest:hover { background: #b71b23; }
.share-button.twitter { background: #5fc6f1; }
.share-button.twitter:hover { background: #55b2d8; }
.share-button.facebook { background: #3c5a97; }
.share-button.facebook:hover { background: #365188; }
.share-button.googleplus { background: #dc4a38; }
.share-button.googleplus:hover { background: #c64232; }
.share-button.email { background: #54c795; }
.share-button.email:hover { background: #4bb386; }

.cart-heading ul    { list-style: none; }

/* Order Methods */
@media (min-width: 930px) {
  /* Cart */
  .cart-heading { background: #f5f5f5; border: 1px solid #d9d9d9; padding: 0 45px; margin-bottom: 35px; height: 120px; margin-top: 10px; }
  .cart-heading h2 { font-size: 28px; color: #222; font-weight: 400; float: left; margin: 45px 0 0 0; }
  .cart-heading ul { list-style: none; margin: 31px 0 0 0; padding: 0; float: right; width: 70%; display: flex; justify-content: space-between }
  .cart-heading ul li { font-size: 20px; color: #222; display: inline-block; vertical-align: middle; margin: 0; height: 59px; padding: 15px 0 0 75px; box-sizing: border-box; }
  .cart-heading ul li.order-by-phone { background: url('../images/icon-order-methods-large_03.png') left center no-repeat }
  .cart-heading ul li.order-by-fax { background: url('../images/icon-order-methods-large_05.png') left center no-repeat }
  .cart-heading ul li.order-by-email { background: url('../images/icon-order-methods-large_07.png') left center no-repeat }
}

@media (max-width: 930px) {
  /* Cart */
  .cart-heading { background: #f5f5f5; border: 1px solid #d9d9d9; padding: 0 45px 0 0; margin-bottom: 35px; height: 120px; margin-top: 10px; }
  .cart-heading ul li > span    { display: inline-block; vertical-align: top; font-size: 20px; color: #222; padding: 16px 85px; }
  .cart-heading ul li.order-by-phone > span { background: url('../images/icon-order-methods-large_03.png') left center no-repeat }
  .cart-heading ul li.order-by-fax > span { background: url('../images/icon-order-methods-large_05.png') left center no-repeat }
  .cart-heading ul li.order-by-email > span { background: url('../images/icon-order-methods-large_07.png') left center no-repeat }
}

/* Your Order */
.cart-your-order { background: $primary; padding: 0 31px; margin-bottom: 20px; height: 85px; box-sizing: border-box; position: relative; }
.cart-your-order h1 { color: #fff; margin: 0; padding: 27px 0 0 0; font-size: 28px; }

.cart-your-order .items-basket    { position: absolute; top: 16px; right: 30px; }

/* Cart */
.cart-container .form-cart  { background: #f5f5f5; width: 73%; }
.cart-container .form-cart .table-wrapper  { padding: 0 22px; }
.cart-container .form-cart .main.actions  { padding: 15px 32px; background: #eee; }

/* Pagination */
.cart-products-toolbar { padding: 0 22px; }
.cart-products-toolbar-top { margin-bottom: 20px; padding-bottom: 10px; border-bottom: 1px solid #dbdbdb; }
.cart-products-toolbar-bottom { margin-top: 20px; padding-top: 10px; padding-bottom: 10px; border-top: 1px solid #dbdbdb; }
.cart-products-toolbar .pager { display: flex; justify-content: space-between; align-items: center; }

/* Col Headings */
.cart.table-wrapper th.col    { text-transform: uppercase; font-weight: 600; padding-bottom: 22px; }
.cart.table-wrapper th.col.sku    { width: 10%; }
.cart.table-wrapper th.col.item    { width: 30%; }
.cart.table-wrapper th.col.action-remove {  }
.cart.table-wrapper th.col.qty span { padding-right: 54px; }

/* Cart Columns */
.cart.items .action-delete  { color: #9d9d9d; }
.cart.items .product-item-details .gift-options-cart-item   { display: none; }
.cart.items .item-actions   { display: none; }

/* Cart Items */
.cart.table-wrapper .items thead + .item,
.cart.table-wrapper .items > .item    { border-color: #dbdbdb }
.cart.table-wrapper .items > .item:last-child    { border-bottom: 0 }

.cart.table-wrapper .col    { padding: 5px 0; }

/* Cart Qty */
.cart .qty-group input[type=text]     { height: 28px; }
.cart .qty-group .adjust-qty  { height: 28px; }

/* Cart Items Options */
.cart.table-wrapper .col-code span,
.cart.table-wrapper .product-item-details,
.cart.table-wrapper .col-options span,
.cart.table-wrapper .col.action-remove a      { position: relative; top: 5px; }
.cart.table-wrapper .item-options   { font-size: 14px; }
.cart.table-wrapper .item-options dt  { font-weight: 400; }
.cart.table-wrapper .product-item-name + .item-options  { margin: 0; }

.action-towishlist    { display: none; }

/* Continue Links */
.form-cart .actions .continue-links  { float: left; font-size: 16px;  text-transform: uppercase; padding-top: 13px; }
.form-cart .actions .continue-links .sep  { color: $secondary; display: inline-block; vertical-align: top; margin: 0 7px; }
.form-cart .actions .continue-links .continue-shopping,
.form-cart .actions .continue-links .clear-bag  { background: transparent; text-transform: uppercase; border: 0; font-weight: 400; font-family: $mainFont; font-size: 16px; padding: 0; line-height: 23px; color: $secondary; }
.form-cart .actions .continue-links .clear-bag  { margin-top: -1px; }
.form-cart .actions .continue-links .clear-bag:hover  { text-decoration: underline; }

/* Update Links */
.update-links   { float: right; }
.update-links .general-link,
.update-links .update-bag   { background: #fff; border: 0; font-weight: 400; text-transform: uppercase; color: $secondary; padding: 15px 25px; font-size: 16px; margin-left: 10px; font-family: $mainFont; }
.update-links .update-bag.active   { background: $secondary; color: #fff; }

.update-links .general-link:active,
.update-links .general-link:hover,
.update-links .update-bag:active,
.update-links .update-bag:hover     { background: $secondary; color: #fff; }

/* Summary */
.cart-summary   { padding: 120px 25px 25px 25px; width: 24.5%; top: 0 !important; position: relative; }
.cart-summary > .title  { background: $primary; color: #fff; margin: 0 0 20px 0; font-size: 28px; font-weight: 300; padding: 27px 24px; position: absolute; top: 0; left: 0; right: 0; }

/* Summary - Checkout Options */
.checkout-options { background: #fff; padding: 20px; }
.checkout-options .options {  margin: 0 auto; padding-top: 5px; }
.checkout-options .options select { background: #d9d9d9 url('../images/svg/arrow.svg') 95% 50% no-repeat; background-size: 13px 8px; height: 40px; -webkit-appearance: none; -moz-appearance: none; appearance: none; border: 0; padding: 0 15px; border-radius: 3px }
.checkout-options p { font-size: 14px; text-align: center; margin: 0 0 16px }
.checkout-options .actions { padding-top: 20px; margin: 0 auto }
.checkout-options .actions .action.primary { width: 100%; box-sizing: border-box; font-size: 19px; text-transform: uppercase; text-align: center; padding: 18px 0; line-height: 21px }
.checkout-options .actions .action.primary:hover {  }

/* No Items */
.cart-empty   { font-size: 16px; }

@media (max-width: 1450px) {
  /* Actions */
  .update-links .general-link, .update-links .update-bag    { padding: 15px 11px }
}

@media (max-width: 1320px) {
  /* Actions */
  .update-links     { float: left; width: 100%; text-align: left; margin-top: 10px;  }
  .update-links button:first-child     { margin-left: 0; }
  .form-cart .actions .continue-links   { padding-top: 0; }
}

@media (max-width: 1200px) {
  /* Cart */
  .cart.table-wrapper th.col.item     { width: 33%; }
  .cart.table-wrapper th.col.qty     { width: 17%; }
}

@media (max-width: 1160px) {
  /* Heading */
  .cart-heading  { height: auto; }
  .cart-heading h2  { display: none; }
  .cart-heading ul    { width: 100%; margin: 14px 0 13px 0; }
}

@media (max-width: 940px) {
  /* Columns */
  .cart.table-wrapper .col    { display: block; padding: 0;  }
  .cart.table-wrapper .col.col-code    { padding-top: 10px; }
  .cart.table-wrapper .col.col.qty    { position: relative; top: -70px; }
  .cart.table-wrapper .col .option-label   { display: inline !important }
  .cart.table-wrapper .product-item-name    { margin: 0; }
  /* Headers */
  #shopping-cart-table thead  { display: none; }
  /* Remove links */
  .col.action-remove     { display: none !important; }
  .alt-remove-link    { display: block !important }
}

@media (max-width: 900px) {
  /* Cart */
  .cart-container .form-cart .table-wrapper   { padding: 0 15px; }
  .cart-container .form-cart .main.actions      { padding: 15px; }
  /* Buttons */
  .update-links .general-link, .update-links .update-bag    { margin-left: 1px; padding: 15px 6px }
}

@media (max-width: 768px) {
  /* Layout */
  .checkout-cart-index .columns     { display: block; }
  .cart-container .form-cart,
    /* Sidebar */
  .cart-summary     { width: 100%; float: none; margin-top: 30px; }
  /* Table */
  #shopping-cart-table thead   { display: none; }
  .cart-container .form-cart .table-wrapper     { padding: 0 10px 0 20px; box-sizing: border-box }
  /* Items */
  .cart.table-wrapper .items > .item    { border-top: 0 !important }
  .cart.table-wrapper .col    { width: 100% !important }
  .cart.table-wrapper .col.col.qty    { text-align: right; position: relative; top: -70px; }
  /* Headings */
  .cart-heading ul  { padding-left: 20px; }
  .cart-heading ul li   { margin: 0 0 10px; }
  .cart-heading ul li > span     { padding: 0px 15px 0px 85px; height: 63px; font-size: 16px; box-sizing: border-box; padding-top: 10px; }
  /* Before */
  .cart.table-wrapper .col.qty:before   { display: none; }
  .cart.table-wrapper .col.price:before,
  .cart.table-wrapper .col.subtotal:before,
  .cart.table-wrapper .col.msrp:before    {  }
  /* Buttons */
  .update-links .general-link, .update-links .update-bag    { padding: 15px 10px; margin-left: 8px; }
}

@media (max-width: 610px) {
  /* Actions */
  .update-links .general-link,
  .update-links .update-bag     { width: 100%; box-sizing: border-box; margin: 0 0 10px; }
  .form-cart .actions .continue-links     { float: none; text-align: center; margin: 0 auto; }
}

@media (max-width: 360px) {
  /* Qty */
  .cart.table-wrapper .col.col.qty    { top: 0; text-align: left; margin: 14px 0 20px; }
  .form-cart .actions .continue-links a     { display: block; text-align: center; }
  .form-cart .actions .continue-links .sep    { display: none; }
}

@media (max-width: 345px) {
  /* Basket */
  .cart-your-order .items-basket    { display: none; }
}
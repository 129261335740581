.cta-home-top {
    width: 96%;
    max-width: 1540px;
    margin: 0 auto 10px;

    @media (max-width: 768px) {
        width: calc(100% - 20px);
    }

    @media (min-width: 769px) {
        display: flex;
        justify-content: space-between;
    }
}

.cta-home-top__item {
    padding-bottom: 10px;
    box-sizing: border-box;

    &:last-child {
        padding: 0;
    }

    @media (min-width: 769px) {
        flex: 1 1 50%;
        padding: 0 11px 0 0;
    }
}

.cta-template-a {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #ebebeb;

    @media (min-width: 1024px) {
        flex-direction: row;
        align-items: center;
        height: 340px;
    }
}

.cta-template-a__left {
    padding: 33px 20px;
    text-align: center;

    @media (min-width: 1024px) {
        flex: 1 1 50%;
        padding: 20px 40px 20px 40px;
        text-align: left;
    }

    .action.primary {
        min-width: 240px;
        padding: 21px 10px;
        font-size: 17px;
        text-transform: uppercase;
        text-align: center;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

.cta-template-a__right {
    height: 170px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (max-width: 1023px) {
        order: -1;
    }

    @media (min-width: 1024px) {
        flex: 1 1 50%;
        height: 100%;
    }
}

.cta-template-a__heading {
    margin: 0 0 30px;
    font-size: 27px;
    line-height: 1.3;

    @media (min-width: 1024px) {
        font-size: 30px;
    }
}


/* Layout */
.cms-page-view.page-layout-2columns-right .sidebar-main   { width: 32.4%; float: right; }
.cms-page-view.page-layout-2columns-right .column.main   { width: 59.7%; }

.cms-page-view.page-layout-2columns-right .page-main    { margin-top: 3px; }

/* Banner */
.cms-page-view.page-layout-2columns-right .banner-container    { margin-bottom: 19px;  }

/* Content */
.cms-page-view .content-container   { font-size: 16px; line-height: 24px; }
.cms-page-view .content-container p   { margin: 0 0 14px }
.cms-page-view .content-container .action.primary   { display: inline-block; text-decoration: none; width: 188px; height: 54px; text-transform: uppercase; text-align: center; padding-top: 17px; box-sizing: border-box; margin-top: 20px; }

/* Sidebar */
.cms-sidebar    { margin-bottom: 70px; padding-top: 15px; }
.cms-sidebar img    { display: block; margin-bottom: 15px; }

/* Decorated list */
ul.decorated { margin: 35px 0; padding: 30px 0 30px 50px; list-style: none; border-top: 1px solid #d7d7d7; border-bottom: 1px solid #d7d7d7 }
ul.decorated li { margin: 0; background: url('../images/icon-tick-red.png') left center no-repeat; padding: 11px 0 10px 45px; font-weight: 300; }
ul.decorated li span { }

@media (min-width: 1500px) {
  /* Decorated List */
  ul.decorated    { padding: 30px 0 30px 310px; }
}

/* Layout */
html    { overflow-y: scroll }
body    { color: #222; height: auto; font-family: $mainFont; font-weight: 300; }

.page-main  { width: 96%; padding-left: 0; padding-right: 0; margin-left: auto; margin-right: auto; }

ul, ol, table     { font-family: $mainFont }

/* Text */
strong    { font-weight: 600; }

/* Breadcrumbs */
.page-wrapper > .breadcrumbs   { width: 96%; box-sizing: border-box; padding-left: 0; padding-right: 0; }
.breadcrumbs .items     { color: #afafaf; font-size: 13px; }
.breadcrumbs .items a     { color: #afafaf; }

/* Form Fields */
select,
input[type="text"], input[type="password"], input[type="url"],
input[type="tel"], input[type="search"], input[type="number"],
input[type="datetime"], input[type="email"]   { font-family: $mainFont }

/* Form Fields Focus */
select:focus,
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus    { box-shadow: none !important; }

/* Labels */
.fieldset > .field > .label,
.fieldset > .fields > .field > .label     { font-weight: 400; }

/* Links */
a,
a:visited,
a:active  { color: #222; }
a:hover   { color: $primary; }

/* Page Titles */
.page-title-wrapper   {  }
.page-title-wrapper .page-title   { color: $secondary; font-size: 28px; margin-bottom: 20px; }

/* Buttons */
.action.primary     { background: $secondary; color: #fff; border-color: $secondary; font-weight: 400; font-family: $mainFont; border-radius: 3px; }
.action.primary:focus,
.action.primary:hover,
.action.primary:active    { background: darken($secondary, 5%); border-color: darken($secondary, 5%) }

/* Error */
div.mage-error[generated]   { font-size: 14px; }

/* Messages */
.message.info     { font-size: 16px; font-weight: 300; }

/* Sign Up Popup - Container */
.sign-up-popup-container { position: fixed; left: 0; right: 0; top: 0; bottom: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.35); z-index: 4000; }
.sign-up-popup-container .popup-signup { position: absolute; top: 50%; transform: translateY(-50%); left: 0; right: 0; margin: 0 auto; width: 90%; max-width: 860px; min-height: 430px; background: #fff url('../images/bg-signup.jpg') center right no-repeat; }
.sign-up-popup-container .popup-signup .close-signup-popup { position: absolute; top: 0; right: 0; cursor: pointer; }
.sign-up-popup-container .popup-signup .mask  { position: absolute; left: 0; right: 0; top: 0; bottom: 0; width: 100%; height: 100%; }

/* Sign Up Popup - Detail */
.popup-signup .detail { position: absolute; top: 50%; transform: translateY(-50%); left: 50px; max-width: 380px; }
.popup-signup .detail h3 { color: $secondary; font-size: 28px; margin: 0 0 18px; max-width: 360px; font-weight: 400; }
.popup-signup .detail p { font-size: 16px; color: #222; line-height: 25px; padding-bottom: 18px; }
.popup-signup .detail form { position: relative; }
.popup-signup .detail form input[type=text] { border: 1px solid #ddd; color: #777; height: 56px; font-size: 16px; padding: 0 73px 0 15px; }
.popup-signup .detail form input[type=image] { position: absolute; top: 0; right: 0; }

.popup-signup .detail form input[type=text]::-webkit-input-placeholder { color: #777; }
.popup-signup .detail form input[type=text]::-moz-placeholder { color: #777; }
.popup-signup .detail form input[type=text]:-ms-input-placeholder { color: #777; }
.popup-signup .detail form input[type=text]:-moz-placeholder { color: #777; }

@media (max-width: 900px) {
  /* Newsletter Popup */
  .sign-up-popup-container .popup-signup .mask    { background: rgba(255, 255, 255, 0.9); }
}

@media (max-width: 768px) {
  /* Main */
  .page-main    { width: 100%; padding-left: 10px; padding-right: 10px; box-sizing: border-box }
}

@media (max-width: 545px) {
  /* Newsletter Popup */
  .popup-signup .detail   { left: 20px; right: 20px; }
}
.subcat-listing {
    @media (min-width: 769px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -5px;
    }
}

.subcat-listing__item {
    padding-bottom: 10px;
    box-sizing: border-box;

    @media (min-width: 769px) {
        flex: 1 1 50%;
        padding: 0 5px 10px;
    }
}

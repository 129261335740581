.category-cta-container {
    width: 96%;
    max-width: 1540px;
    margin: 0 auto 50px;

    @media (max-width: 768px) {
        width: calc(100% - 20px);
    }

    @media (min-width: 769px) {
        display: flex;
        justify-content: space-between;
    }
}

.category-cta-container__item {
    padding-bottom: 10px;
    box-sizing: border-box;

    &:last-child {
        padding: 0;
    }

    @media (min-width: 769px) {
        flex: 1 1 (100% / 3);
        padding: 0 11px 0 0;
    }
}

.cta-template-b {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
    background-color: #ebebeb;
}

.cta-template-b__image {
    height: 170px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.cta-template-b__heading {
    display: block;
    padding: 33px 20px;
    margin: 0;
    font-size: 27px;
    line-height: 1.3;

    &:hover,
    &:focus {
        color: #222;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        overflow: hidden;
        white-space: nowrap;
    }
}

.covid-action-info {
    width: 96%;
    max-width: 1540px;
    margin: 0 auto 10px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    color: #fff;
    background: #9e7490;
    box-sizing: border-box;

    @media (max-width: 768px) {
        width: calc(100% - 20px);
    }

    @media (min-width: 768px) {
        padding: 0 20px 0 50px;
    }

    p {
        display: inline-block;
        margin: 0;
        padding: 35px 0 35px 90px;
        font-size: 18px;
        font-weight: 400;
        background: url('/pub/media/freshveg/info-circle.svg?123') no-repeat left center;
        background-size: 68px;
    }
}

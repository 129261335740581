
.ie-fixes {

  /* Navigation */
  .new-navigation ul    { display: block; }
  .new-navigation ul li    { display: block; float: left; margin-right: 2.6%; }

  /* Banner CTA */
  .cta-container    { display: block; overflow: hidden; }
  .cta-container .cta     { float: left; display: block; }

  /* Category CTA */
  .category-cta-container  {  }
  .category-cta-container .category-cta    { overflow: hidden; }
  .category-cta-container .category-cta .cta    { float: left; margin-right: 0.7%; }
  .category-cta-container .category-cta .cta:nth-child(3n)     { margin-right: 0; }

  /* Footer Bar */
  .footer-top-bar-container .footer-top ul    { overflow: hidden; }
  .footer-top-bar-container .footer-top ul li     { float: left; margin-right: 2%; }

  /* Testimonials */
  .testimonal-slider .testimonial     { width: 100%; }

  /* Cart Heading */
  .cart-heading ul li     { margin-right: 20px; }

  /* Cols */
  .footer-container .footer .footer-cols    { overflow: hidden; }
  .footer-container .footer .footer-cols .col     { width: 22%; margin-right: 3%; float: left; }

}

.ie9, .ie10 {
  @extend .ie-fixes;
}
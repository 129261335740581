
/* List */
.post-list      { list-style: none; margin: 0; padding: 0; }
.post-list li      { margin: 0 0.71% 20px 0; padding: 0; width: 32.857%; float: left; background: #eee; cursor: pointer; }
.post-list li:nth-child(3n)      { margin-right: 0; }
.post-list li .post-detail    { padding: 30px 40px; font-size: 16px; color: #222; }
.post-list li .post-detail p   { margin: 0; }
.post-list li .post-title   { font-size: 28px; padding-bottom: 8px; margin: 0; }
.post-list li .post-title a   { color: $secondary; text-decoration: none; }
.post-list li .post-ftimg-hld   { position: relative; }
.post-list li .post-ftimg-hld img     { display: block; width: 100%; }
.post-list li .post-ftimg-hld .mask     { position: absolute; left: 0; right: 0; top: 0; bottom: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.4); }

/* Post List Hover */
.post-list li:hover   { background: $secondary; color: #fff; }
.post-list li:hover .post-title a   { color: #fff; }
.post-list li:hover .post-detail    { color: #fff; }

/* Post View Layout */
.blog-post-view.page-layout-2columns-right .column.main   { width: 59.4%; float: left; }
.blog-post-view.page-layout-2columns-right .sidebar-main   { width: 32.46%; float:right; }

.post-text-hld    { padding: 10px 0; }

/* News Filter */
.news-filter { width: 350px; float: right; margin-bottom: 25px; }
.news-filter select { height: 51px; border: 1px solid #e5e5e5; box-sizing: border-box; padding: 0 15px; font-size: 16px; color: #757575; border-radius: 3px; background: url('../images/svg/arrow-red.svg') 95% 50% no-repeat; background-size: 17px 10px; -webkit-appearance: none; -moz-appearance: none; appearance: none; }

/* Post View */
.post-nextprev-hld    { display: none; }
.post-description p  { font-size: 16px; line-height: 25px; margin-bottom: 18px; }
.post-description .action.primary   { min-width: 188px; height: 54px; box-sizing: border-box; text-align: center; text-transform: uppercase; font-size: 16px; line-height: 37px; margin-top: 15px; }
.post-detail-footer  { position: relative; margin-bottom: 30px; }

/* Post View Sidebar */
.post-gallery   { margin-bottom: 70px; }
.post-gallery img  { display: block; margin-bottom: 11px; width: 100%; }

@media (max-width: 880px) {
  /* List */
  .post-list li     { width: 49%; margin-right: 1.5%; }
  .post-list li:nth-child(3n)   { margin-right: 1.5%; }
  .post-list li:nth-child(2n)   { margin-right: 0; }
}

@media (max-width: 768px) {
  /* Select category */
  .news-filter    { width: 100%; box-sizing: border-box }
  .news-filter select     { background-position: 97% 50% }
}

@media (max-width: 575px) {
  /* List */
  .post-list li     { width: 100%; margin-right: 0 !important; min-height: 0 !important }
}

@media (max-width: 340px) {
  /* Share */
  .post-detail-footer     { padding-top: 70px; }
  .share-buttons-group    { left: 97px; right: auto; }
}
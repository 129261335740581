
/* Header Top */
.header-top-container {  background: $primaryDark; height: 35px; }
.header-top-container .header-top { max-width: 1540px; margin: 0 auto; width: 96%; }

/* Top Welcome/Login */
.header-top-container .header-top .header-login { color: #fff; float: left; }
.header-top-container .header-top .header-login p { margin: 0; padding: 7px 0 9px; }
.header-top-container .header-top .header-login p a { color: #fff; text-decoration: none; }
.header-top-container .header-top .header-login p a:hover { text-decoration: underline; }

/* Top Links */
.header-top-container .header-top .header-links { float: right; }
.header-top-container .header-top .header-links ul { list-style: none; margin: 0; padding: 0; }
.header-top-container .header-top .header-links ul li { margin: 0; padding: 0; display: inline-block; vertical-align: top; }
.header-top-container .header-top .header-links ul li a { color: #fff; display: inline-block; vertical-align: top; padding: 7px 16px 9px; text-decoration: none; font-weight: 300; }
.header-top-container .header-top .header-links ul li a:hover { background: $primary; }

.header-top-container .header-top .header-links { float: right; }
.header-top-container .header-top .header-links ul { list-style: none; margin: 0; padding: 0; }
.header-top-container .header-top .header-links ul li { margin: 0; padding: 0; display: inline-block; vertical-align: top; }
.header-top-container .header-top .header-links ul li a { color: #fff; display: inline-block; vertical-align: top; padding: 7px 16px 9px; text-decoration: none; font-weight: 300; }
.header-top-container .header-top .header-links ul li a:hover { background: $primary; }

.header-top-container .header-top .header-links ul li.link-basket   { position: relative; cursor: pointer; }
.header-top-container .header-top .header-links ul li.link-basket > a  { background: transparent !important; padding-right: 31px; background-size: 13px 18px }
.header-top-container .header-top .header-links ul li.link-basket .bag-image  { position: absolute; top: 0;  right: -11px; padding: 4px 11px; border-radius: 150px; margin: 2px 0; }
.header-top-container .header-top .header-links ul li.link-basket .bag-image img  { width: 13px; height: 18px; position: relative; top: 1px; }

.header-top-container .header-top .header-links ul li.link-basket:hover .bag-image  { background: #b72922; }

/* Main Header */
.page-header    { background: $primary; border: 0; margin-bottom: 0; }
.header.content     { padding: 0; height: 105px; width: 96%; margin: 0 auto }

/* Search */
.header.content .block-search     { margin-top: 28px; width: 400px; }
.header.content .block-search input     { height: 50px; border: 0; font-size: 16px; color: #222; padding-left: 15px; }
.header.content .block-search .action.search    { top: 9px; }

.header.content .block-search .action.search    { top: 5px; opacity: 1; }
.header.content .block-search .action.search:before   { content: ' '; width: 30px; height: 30px; background: url('../images/svg/search.svg') center center no-repeat; background-size: 22px 20px; }

/* Phone */
.header-phone       { font-size: 20px; font-weight: 300; color: #fff; float: right; margin: 40px 40px 0 0; padding-left: 37px; background: url('../images/svg/phone.svg') center left no-repeat; background-size: 20px 22px }
.header-phone a       { font-weight: 400; color: #fff; }

/* Logo */
.logo   { margin: 9px 0 0 0; }
/*.logo img     { height: 86px; transition: height 0.1s }*/

@media (min-width: 1050px) {
  /* Floating Header */
  .floating-header {

    /* Layout */
    .page-wrapper    { padding-top: 210px; } /* 187 */
    .page-header    { position: fixed; top: -35px; left: 0; right: 0; width: 100%; z-index: 2000; background: $primary; }
    .nav-sections-new  { position: fixed; width: 100%; top: 82px; left: 0; right: 0; z-index: 2500; box-shadow: 0px 7px 20px -11px rgba(0, 0, 0, 0.4); }

    /* Header */
    .logo img  { height: 63px; }
    .header-phone    { margin-top: 23px; }
    .header.content   { height: 82px; }
    .header.content .block-search   { margin-top: 15px; }
    .block-search .control  { padding-bottom: 0; }
  }
}

@media (max-width: 1060px) {
  /* Top */
  .header-top .no-tablet  { display: none !important; }
  /* Search */
  .header.content .block-search   { width: 250px; }
}

@media (max-width: 850px) {
  /* Search */
  .header.content .block-search   { display: none; }
  /* Phone */
  .header-phone     { margin-right: 0; }
}

@media (max-width: 768px) {
  /* Not needed on mobile */
  .header-phone,
  .header-top-container .header-top .header-links,
  .header-top-container .header-top .header-login     { display: none; }
  /* Logo */
  .logo   { max-width: none; }
  /* Mobile Header */
  .header-mobile { display: block !important }
  .header-mobile ul { list-style: none; margin: 0; padding: 0; display: flex; justify-content: space-between; height: 35px; }
  .header-mobile ul li { margin: 0; padding: 7px 0 0; text-align: center; width: 25%; }
  .header-mobile ul li a { }
  .header-mobile ul li:active     { background: $primary }
  .header-mobile ul li a:hover { }
  .header-mobile ul li a img { }
  /* Search */
  .header.content     { width: 100%; padding: 0 2%; box-sizing: border-box }
  .mobile-search-active {
    .header.content .block.block-search     { display: block !important; position: absolute; top: 0; left: 0; right: 0; width: 100% !important; z-index: 5000; background: $primaryDark; padding: 20px; margin: 0; box-sizing: border-box }
    .header.content .block.block-search .block-content  { background: #fff; }
    .header.content .block.block-search .control    { border-top: 0; padding: 0 20px }
    .header.content .block.block-search .label    { position: absolute; top: 30px; right: 31px; width: 35px; height: 33px; clip: auto }
    .header.content .block.block-search input     { position: static; margin: 0; }
    .header.content .block-search .action.search  { display: none; }
    .block-search .label:before     { content: ' '; width: 30px; height: 30px; background: url('../images/svg/search.svg') center center no-repeat; background-size: 22px 20px; }
  }
  .new-navigation   { text-align: center; }
}

@media (max-width: 400px) {
  /* Search */
  .mobile-search-active .header.content .block-search input     { padding-left: 10px; }
  /* Logo */
  .logo   { padding-left: 10px; }
}

.wishlist-index-index .page-title-wrapper     { padding-bottom: 15px; }

#wishlist-view-form {
  /* Toolbar */
  .actions-toolbar  { background: #f5f5f5; padding: 20px; margin-top: 20px; }
  /* Product List */
  .wishlist-grid ol > li       { width: 100%; float: none; display: block; clear: both; overflow: hidden; border-bottom: 1px solid #eee; padding-bottom: 10px; margin-bottom: 10px; }
  .wishlist-grid ol > li:last-child       { border-bottom: 0 }
  .wishlist-grid li .product-item-name a    { color: #aaa; }
  .wishlist-grid li .product-item-name  { margin: 0 25px 0 0; width: 50%; }
  .wishlist-grid li .product-item-name,
  .wishlist-grid li .product-item-actions,
  .wishlist-grid li .box-tocart,
  .wishlist-grid li .fieldset,
  .wishlist-grid li .product-options,
  .wishlist-grid li .field.qty,
  .wishlist-grid li .product-item-inner   { display: inline-block; vertical-align: top; }
  .wishlist-grid li .product-item-inner   { float: right; }
  .wishlist-grid li .product-item-info    { width: auto; display: block; }
  .wishlist-grid li input[type="number"]  { border: 1px solid #eee }
  .wishlist-grid li .qty  { margin: 0; }
  .wishlist-grid li .qty label  {  }
  .wishlist-grid li .fieldset > .field:not(.choice) > .control    { width: 64%; }
  .wishlist-grid li .fieldset > .field:not(.choice) > .label    {  }
  .wishlist-grid li .product-options ul     { list-style: none; margin: 0; padding: 6px 0 0; }
  .wishlist-grid li .product-options ul li    { padding: 0; margin: 0; }
  .wishlist-grid li .product-item-actions .action.primary    { text-transform: uppercase; }
}

.wishlist-index-index.account .fieldset > .field:not(.choice) > .label     { padding-top: 6px; text-transform: uppercase; }

/* Action Toolbar Buttons */
.wishlist-index-index .actions-toolbar .action.primary    { background: #fff; color: $secondary; text-transform: uppercase; border: 0; font-weight: 400; padding: 15px 25px; font-size: 16px; margin-right: 10px; }
.wishlist-index-index .actions-toolbar .action.primary:active,
.wishlist-index-index .actions-toolbar .action.primary:hover    { background: $secondary; color: #fff }

/* Back Button */
.wishlist-index-index .actions-toolbar .secondary a.back.action    { text-transform: uppercase; color: $secondary }

@media (max-width: 1180px) {
  /* List */
  #wishlist-view-form .wishlist-grid li .product-item-name    { width: 35%; }
  #wishlist-view-form .wishlist-grid li input[type="number"]    { max-width: 60px; }
  /* Qty */
  .wishlist-grid li .fieldset > .field:not(.choice) > .label    { display: none; }
}

@media (max-width: 768px) {
  /* Account */
  .account .page.messages     { margin: 0; }
  /* List */
  .product-item-actions     { margin-left: 10px; }
  #wishlist-view-form .wishlist-grid li .product-item-name    { width: 34%; }
  #wishlist-view-form .wishlist-grid li .qty label  { display: none; }
}

@media (max-width: 550px) {
  /* List */
  .wishlist-grid .product-item-name   { display: block; clear: both; float: left; margin: 0; padding-bottom: 10px; }
  #wishlist-view-form .wishlist-grid ol > li    { padding-bottom: 20px; margin-bottom: 20px; }
  #wishlist-view-form .wishlist-grid li   { border-bottom: 1px solid #eee; margin-bottom: 18px; }
  #wishlist-view-form .wishlist-grid li:last-child  { border-bottom: 0; }
  #wishlist-view-form .wishlist-grid li .product-item-inner     { float: left; overflow: hidden; clear: both; }
  #wishlist-view-form .wishlist-grid li .product-options    { float: left; clear: both; width: 100%; padding-bottom: 15px; }
}
/* Layout */
.catalogsearch-result-index.page-layout-2columns-right .column.main,
.catalog-category-view.page-layout-2columns-right .column.main {
    width: 73.1%;
    float: left;
}

.catalogsearch-result-index.page-layout-2columns-right .sidebar-main,
.catalog-category-view.page-layout-2columns-right .sidebar-main {
    width: 24.6%;
    float: right;
    padding-left: 0;
}

/* Hide Filters */
.catalog-category-view.page-layout-2columns-right .block.filter {
    display: none;
}

/* Toolbars */
.toolbar-products {
    display: none;
}

/* Hide Messages */
.catalog-category-view .message.success {
    display: none !important;
}

/* Child Letters */
.child-letters {
    list-style: none;
    margin: 0 0 25px;
    padding: 5px 0;
    text-align: center;
    background: #f5f5f5;
}

.child-letters li {
    margin: 0;
    padding: 0;
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
}

.child-letters li a {
    color: #222;
    text-decoration: none;
    display: inline-block;
    vertical-align: top;
    padding: 6px 11px;
    border-radius: 55px;
    cursor: pointer;
    margin: 0 5px;
}

.child-letters li a:hover {
    background: #46804b;
    color: #fff;
}

/* Items - Basket */
.items-basket {
    height: 43px;
}

.items-basket .label {
    color: #fff;
    font-size: 18px;
    padding: 18px 12px 0 0;
    display: inline-block;
}

.items-basket .bag {
    display: inline-block;
    vertical-align: top;
    height: 53px;
    width: 31px;
    background: url('../images/icon-basket-items.png') center center no-repeat;
    position: relative;
    text-align: center;
}

.items-basket .bag .quantity {
    position: absolute;
    text-align: center;
    top: 19px;
    left: -1px;
    right: 0;
    color: $primary;
    font-size: 18px;
    font-weight: 400;
}

/* Category List */
.category-list {
    list-style: none;
    margin: 0 0 40px;
    padding: 0;
}

.category-list li {
    margin: 0;
    padding: 0;
}

.category-list .category-item .category-link {
    display: block;
    background: #89bba4 url('../images/arrow-white-down.png') 98% 50% no-repeat;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px 20px;
    margin-bottom: 15px;
}

.category-list .category-item.category-empty .category-link {
    background-color: #89bba4;
}

.category-list .category-item .product-list {
    padding: 0 0 20px;
}

.category-list .category-item .category-link.active {
    background-image: url('../images/arrow-white-up.png');
}

// Column headings
.category-heading-columns {
    margin-bottom: 18px;
    padding: 0 10px;

    @media (max-width: 1199px) {
        display: none;
    }

    ul {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    li {
        text-transform: uppercase;
    }
}

// Column widths
.product-list {
    .col {
        padding: 0 14px;
        box-sizing: border-box;
    }

    .col.col-image {
        @media (min-width: 1200px) {
            flex-shrink: 0;
            width: 90px;
        }
    }

    .col.col-description {
        @media (min-width: 1200px) {
            flex: 0 0 38%;
        }
    }

    .col.col-options {
        @media (min-width: 1200px) {
            flex: 0 0 15%;
        }
    }

    .col.col-qty {
        @media (min-width: 1200px) {
            flex: 0 0 18%;
        }
    }

    .col.col-price {
        @media (min-width: 1200px) {
            flex: 0 0 5%;
        }
    }
}

/* Product List */
.category-list .category-item {
    .product-list ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .product-list ul li {
        padding: 10px;
    }

    .product-list ul li:nth-child(2n) {
        background: #f5f5f5;
    }

    .product-list ul li form {
        display: flex;
        flex-wrap: wrap;

        @media (min-width: 1200px) {
            flex-wrap: nowrap;
        }
    }

    // Columns
    .product-list ul li .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    // Image
    .product-list ul li .col-image {
        width: 90px;
    }

    // Description
    .product-list ul li .col-description {
        width: 100%;

        @media (min-width: 500px) {
            margin-bottom: 10px;
        }

        @media (min-width: 1200px) {
            margin-bottom: 0;
        }
    }

    // Options
    .product-list ul li .col-options {
        width: 100%;
        margin: 10px 0;

        @media (min-width: 500px) {
            float: left;
            width: 25%;
            margin: 0;
        }
    }

    .product-list ul li .col-options select {
        max-width: 160px;
        height: 28px;
        color: #222;
        background: #fff url('../images/arrow-green-down-small.png') 95% 50% no-repeat;
        border: 1px solid #d9d9d9;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin-top: 4px;
        padding-left: 10px;
        box-sizing: border-box;
    }

    // QTY
    .product-list ul li .col-qty {
        width: 100%;

        @media (min-width: 500px) {
            width: 25%;
        }

        .qty-group {
            width: 40px;
        }
    }

    // Price
    .product-list ul li .col-price {
        width: 100%;
        margin: 10px 0;

        @media (min-width: 500px) {
            width: 25%;
            margin: 0;
        }
    }

    // Add
    .product-list ul li .col-add {
        width: 100%;
        padding-right: 0;

        @media (min-width: 500px) {
            width: 25%;
        }
    }

    .product-list ul li .col-add .action.primary {
        text-transform: uppercase;
        margin-right: 4px;
        min-width: 75px;
    }
}

/* Qty Group */
.qty-group {
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
    background: #d9d9d9;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 0 30px;
    position: relative;
    margin-top: 2px;
}

.qty-group input[type='text'] {
    width: 40px;
    height: 26px;
    padding: 0;
    text-align: center;
    border: 0;
    font-size: 14px;
    color: #222;
}

.qty-group .adjust-qty {
    width: 30px;
    height: 28px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    cursor: pointer;
}

.qty-group .adjust-qty.minus {
    background: url('../images/icon-minus.png') center center no-repeat;
    left: 0;
}

.qty-group .adjust-qty.plus {
    background: url('../images/icon-plus.png') center center no-repeat;
    right: 0;
}

.qty-group .adjust-qty:active {
    background-color: #ccc;
}

/* Subbox - Order Methods */
.list-sidebar .sub-box.order-methods {
    border: 1px solid #d9d9d9;
    padding: 25px;
    margin-bottom: 60px;
    background: #f5f5f5;
}

.list-sidebar .sub-box.order-methods h3 {
    padding-bottom: 25px;
    font-size: 28px;
}

.list-sidebar .sub-box.order-methods ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.list-sidebar .sub-box.order-methods ul li {
    margin: 0 0 20px;
    padding: 0 0 0 70px;
    min-height: 51px;
    font-size: 20px;
    font-weight: 300;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 53px 49px;
}

.list-sidebar .sub-box.order-methods ul li span {
    display: inline-block;
    vertical-align: top;
    padding: 10px 0 0 0;
}

/* Search results */
.catalogsearch-result-index .block.filter {
    display: none !important;
}

@media (max-width: 1000px) {
    /* List */
    .category-list .category-item .product-list ul li {
        border-bottom: 1px solid #ddd;
    }

    .category-list .category-item .product-list ul li:last-child {
        border-bottom: 0;
    }
}

@media (min-width: 768px) and (max-width: 895px) {
    /* Sub Box Methods */
    .list-sidebar .sub-box.order-methods ul li {
        background-image: none !important;
        padding: 0 0 20px;
        border-bottom: 1px solid #ccc;
        min-height: 0;
    }

    .list-sidebar .sub-box.order-methods ul li:last-child {
        border: 0;
    }
}

@media (max-width: 768px) {
    /* Layout */
    .catalog-category-view.page-layout-2columns-right .sidebar-main {
        order: 2;
    }

    /* Category */
    .category-list .category-item .category-link {
        background-position: 97% 50%;
    }
}

@media (max-width: 500px) {
    /* Category */
    .category-list .category-item .category-link {
        background-position: 94% 50%;
    }
}


/* Layout */
.contact-index-index.page-layout-2columns-right .column.main  { width: 59.4%; float: left; }
.contact-index-index.page-layout-2columns-right .sidebar-main  { width: 32.4%; float: right; padding-left: 0; }

/* Contact */
.contact-detail { }
.contact-detail .section { border-bottom: 1px solid #d7d7d7; padding-bottom: 25px; margin-bottom: 35px; }
.contact-detail .section h2 { color: $secondary; font-size: 28px; margin: 0 0 18px; }
.contact-detail .section p { font-size: 16px; color: #222; margin: 0 0 14px; line-height: 26px }
.contact-detail .section p a { text-decoration: underline; }
.contact-detail .section p a:hover { }

/* Sidebar */
.contact-sidebar { }
.contact-sidebar .cta { position: relative; margin-bottom: 10px; height: 400px; }
.contact-sidebar .cta img { display: block; width: 100%; }
.contact-sidebar .cta .overlay { position: absolute; top: 50%; transform: translateY(-50%); left: 10%; right: 10%; text-align: center; font-size: 16px; }

.contact-sidebar .cta     { background: url('../images/photo-mask-sidebar_03.jpg') center center no-repeat; background-size: cover }

/* Sidebar Overlay */
.contact-sidebar .cta .overlay h3 { color: #fff; font-size: 30px; font-weight: 400; padding-bottom: 15px; }
.contact-sidebar .cta .overlay h3 a { color: #fff; }
.contact-sidebar .cta .overlay h3 a:hover { }

/* Sidebar Overlay Content */
.contact-sidebar .cta .overlay p { color: #fff; margin: 0 0 18px }
.contact-sidebar .cta .overlay p a { color: #fff; }
.contact-sidebar .cta .overlay p a:hover { }
.contact-sidebar .cta .overlay p.sub     { font-size: 23px; margin: 0 0 23px; }
.contact-sidebar .cta .overlay p:last-child     { margin-bottom: 0; }

.contact-sidebar .cta table   { text-align: left; color: #fff; }
.contact-sidebar .cta table tr td   { border-bottom: 1px solid #fff; padding: 8px 0; }
.contact-sidebar .cta table tr:last-child td   { border-bottom: 0 }

/* Sidebar Opening Hours */
.contact-sidebar .cta.cta-opening-hours   { background-image: url('../images/photo-mask-sidebar_06.jpg') }
.contact-sidebar .cta.cta-opening-hours .overlay h3   { padding-bottom: 0; margin: 0 0 13px; text-align: left; }
.contact-sidebar .cta.cta-opening-hours a.action.primary    { float: left; font-size: 17px; background: rgba(0, 0, 0, 0.4); border: 1px solid #fff; padding: 15px 39px; text-transform: uppercase; }

/* Form */
.form.contact   { padding: 0 0 50px; }
.form.contact h2 { color: $secondary; font-size: 28px; padding-bottom: 20px; }
.form.contact .group  { display: flex; justify-content: space-between }
.form.contact .group .control { max-width: 49%; }
.form.contact .control { width: 100%; margin-bottom: 20px; }
.form.contact .control .input-text { width: 100%; border: 1px solid #e5e5e5; height: 48px; box-sizing: border-box; padding: 0 15px; border-radius: 3px; font-size: 17px; font-family: $mainFont; }
.form.contact .control textarea.input-text     { height: 108px; padding: 15px; font-family: $mainFont; }
.form.contact .fieldset   { margin-bottom: 0; }
.form.contact .actions-toolbar    { margin-left: 0; }
.form.contact .actions-toolbar .action.primary   { width: 450px; height: 55px; box-sizing: border-box; font-size: 16px; text-transform: uppercase; }

.form.contact .control .input-text::-webkit-input-placeholder,
.form.contact .control textarea.input-text::-webkit-input-placeholder {
  color: #777;
}
.form.contact .control .input-text::-moz-placeholder,
.form.contact .control textarea.input-text::-moz-placeholder {
  color: #777;
}
.form.contact .control .input-text:-ms-input-placeholder,
.form.contact .control textarea.input-text:-ms-input-placeholder {
  color: #777;
}
.form.contact .control .input-text:-moz-placeholder,
.form.contact .control textarea.input-text:-moz-placeholder {
  color: #777;
}

@media (max-width: 1000px) {
  /* Sidebar */
  .contact-index-index.page-layout-2columns-right .sidebar-main,
  .contact-index-index.page-layout-2columns-right .column.main    { width: 100%; float: none; }
  .contact-sidebar .cta    { height: 300px; }
  .contact-sidebar .cta.cta-opening-hours     { height: 380px; }
  /* Form */
  .form.contact   { padding-bottom: 10px; }
}

@media (max-width: 520px) {
  /* Sidebar */
  .contact-sidebar .cta   { height: 370px; }
  .contact-sidebar .cta.cta-opening-hours     { height: 440px; }
}

@media (max-width: 400px) {
  /* Contact Form */
  .form.contact .group    { display: block; }
  .form.contact .group .control     { width: 100%; max-width: none; }
  .form.contact .actions-toolbar .action.primary  { width: 100%; }

  .contact-sidebar .cta   { height: 410px; }
  .contact-sidebar .cta .overlay    { left: 6%; right: 6%; }
}
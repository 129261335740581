// Minicart overrides for sidebar only
.list-sidebar .minicart-wrapper {
    float: none;
    margin: 0 0 30px;

    .block-minicart {
        position: static;
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        box-shadow: none;
        border: 0;

        &:before,
        &:after {
            display: none;
        }

        .subtotal {
            display: flex;
            justify-content: space-between;
            margin: 0 30px;
            padding: 24px 0;
            font-size: 19px;
            text-align: left;
            border-bottom: 1px solid #d9d9d9;
        }

        .amount .price-wrapper:first-child .price {
            font-size: 19px;
            font-weight: 600;
        }
    }

    .block-content {
        border: 1px solid #d9d9d9;
        border-top: 0;

        > .actions {
            margin: 0;
            padding: 20px 30px 30px;

            .action.primary {
                display: block;
                padding: 26px 15px;
                font-size: 19px;
                text-transform: uppercase;
            }
        }
    }

    .minicart-items-wrapper {
        height: auto !important; // override inline styles
        margin: 0;
        border: 0;
        padding: 30px 30px 0;
    }

    // Product item
    .minicart-items .product-item {
        padding: 10px 0;
        border-bottom: 1px solid #d9d9d9;

        &:not(:first-child) {
            border-top: 0;
        }

        .product-image-container {
            width: 50px !important; // override inline styles

            .product-image-photo {
                width: auto !important; // override inline styles
                height: auto !important; // override inline styles
            }
        }
    }

    .minicart-items .product-item-details {
        padding-left: 72px;
        font-size: 13px;

        .price-container .price {
            font-size: 13px;
        }
    }

    .minicart-items .product-item-pricing .label {
        text-transform: uppercase;
        font-weight: 600;
        width: 3rem;
    }

    .product .actions {
        position: relative;
    }
}

// Minicart top
.minicart-top {
    position: relative;
    padding: 25px;
    color: #fff;
    background-color: #e63329;

    .items-total {
        position: absolute;
        top: 20px;
        right: 23px;

        .label {
            display: inline-block;
            padding: 18px 12px 0 0;
            font-size: 18px;
            color: #fff;
        }

        .bag {
            position: relative;
            display: inline-block;
            width: 31px;
            height: 53px;
            text-align: center;
            background: url(../images/icon-basket-items.png) center center no-repeat;
            vertical-align: top;
        }

        .bag .count {
            position: absolute;
            top: 19px;
            right: 0;
            left: -1px;
            font-size: 18px;
            font-weight: 400;
            color: $primary;
            text-align: center;
        }
    }
}

.minicart-top__title {
    font-size: 28px;
}


.block-minicart .qty-group 	{
	float: none;
	clear: both;
	display: block;
	width: 41px;
	margin-top: 60px;
	margin-left: 80px;
}

.block-minicart .minicart-items .item-qty 	{
	margin: 0;
}

.list-sidebar .minicart-wrapper .minicart-items .product-item-details 	{
	float: left;
	padding: 0 0 0 30px;
}

.list-sidebar .minicart-wrapper .minicart-items .product-item 	{
	border-color: #ededed;
	padding: 20px 0;
}

.minicart-items .product-item-name 	{
	margin: 0;
}

.minicart-wrapper.mask .block-content 	{
	position: relative;
}

.minicart-wrapper.mask .block-content:after 	{
	content: ' ';
	display: block;
	box-sizing: border-box;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3);
	z-index: 20;
}

@media (min-width: 768px) {

	.list-sidebar .minicart-wrapper .minicart-items .product-item-details 	{
		width: calc(100% - 80px);
		padding-bottom: 10px;
	}

}

@media (min-width: 1480px) {

	.list-sidebar .minicart-wrapper .minicart-items .product-item-details 	{
		width: auto;
	}

	.block-minicart .qty-group 	{
		display: inline-block;
		width: auto;
		margin: 0;
		clear: none;
		float: right;
	}

}
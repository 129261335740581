
/* Container */
.checkout-container   { margin: 30px 0 20px; }

/* Progress Bar */
.opc-progress-bar-item > span   { font-family: $mainFont; }
.opc-progress-bar-item._active > span:before,
.opc-progress-bar-item._active:before     { background: $primary }

/* Titles */
.opc-wrapper .step-title  { font-family: $mainFont }

/* Form */
.opc-wrapper .fieldset > .field > .label,
.opc-wrapper .fieldset > .field.required > label    { font-size: 16px; font-weight: 300; }

/* Delivery Options */
.table-checkout-shipping-method tbody td:nth-child(2n)  { display: none; }

/* Fields */
.opc-wrapper {
  .fieldset > .field .note:before, .fieldset > .fields > .field .note:before    { margin-top: 0; }
  .form-login .fieldset .note span   { color: #666; }
  select, input[type="text"], input[type="password"], input[type="url"], input[type="tel"],
  input[type="search"], input[type="number"], input[type="datetime"], input[type="email"]   { font-size: 16px; height: 48px; border: 1px solid #e5e5e5; }
}

/* Mini Cart */
.opc-block-summary .product-item .price   { display: none; }

/* Information */
.shipping-information-title span     { font-family: $mainFont; font-size: 21px; }

/* Discount */
#discount-form  { display: none !important; }
#discount-form .action  { @extend .action.primary }
.checkout-payment-method .payment-option-title .action-toggle     { color: #222; font-size: 16px; font-weight: 300; }

/* Payment Method */
.payment-method   { font-weight: 300; font-size: 16px; }

/* Summary */
.opc-block-summary  { padding-bottom: 30px; }
.opc-block-summary > .title     { font-family: $mainFont }
.opc-block-summary .items-in-cart > .title    { padding-top: 16px; padding-bottom: 16px; }
.opc-block-summary .items-in-cart > .title:after    { top: 18px; }
.opc-block-summary .table-totals    { display: none !important }
.opc-block-summary .table-totals .grand .amount strong    { font-weight: 400; }

/* Actions */
.checkout-shipping-method .actions-toolbar .action.primary    { min-width: 100px }

/* Authentication */
.authentication-dropdown  { margin-top: 20px; }
.authentication-dropdown label.label  { padding: 0 0 10px !important; font-size: 16px !important; text-transform: uppercase; font-weight: 300 !important; }
.authentication-dropdown .input-text   { height: 48px; font-size: 15px; }
.block-authentication .actions-toolbar > .primary .action     { color: #fff !important; @extend .action.primary }
body._has-modal-custom .modal-custom-overlay  { display: none !important }
.checkout-container .authentication-wrapper    { margin-top: 0; }
.checkout-container .authentication-wrapper .action    { color: #222; }

/* Success */
.checkout-success     { font-size: 18px; }
.checkout-onepage-success #registration     { display: none !important }
.checkout-onepage-success .actions-toolbar    { padding-top: 16px; }
.checkout-onepage-success .actions-toolbar .primary a.action  { font-size: 16px; padding: 15px 25px; }
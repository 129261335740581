
/* Footer Content */
.footer.content   { border: 0 }

/* Footer */
.page-footer    { background: #ebebeb; }

/* Footer Top Bar */
.footer-top-bar-container { background: $primary; }
.footer-top-bar-container .footer-top { max-width: 1540px; margin: 0 auto; width: 96%; }

@media (min-width: 1500px) {
  /* Footer Bar */
  .footer-top-bar-container .footer-top ul { list-style: none; margin: 0; padding: 0; display: flex; justify-content: space-between  }
  .footer-top-bar-container .footer-top ul li { margin: 0; color: #fff; font-size: 18px; padding: 30px 13px 30px 55px; background: url('../images/svg/tick-white.svg') center left no-repeat; background-size: 40px 40px }
}
@media (max-width: 1500px) {
  /* Footer Bar */
  .footer-top-bar-container .footer-top ul    { margin: 0; padding: 0; list-style: none; }
  .footer-top-bar-container .footer-top ul li   { margin: 0; padding: 0; text-align: center; }
  .footer-top-bar-container .footer-top ul li span  { display: inline-block; vertical-align: top; color: #fff; font-size: 18px; padding: 30px 13px 30px 55px; background: url('../images/svg/tick-white.svg') center left no-repeat; background-size: 40px 40px }
}

/* Footer */
.footer-container {  }
.footer-container .footer { max-width: 1540px; margin: 0 auto; min-height: 200px; width: 96%; }

.footer-container .footer .footer-cols { display: flex; justify-content: space-between }
.footer-container .footer .footer-cols .col { padding: 70px 0 40px; font-size: 18px;  }
.footer-container .footer .footer-cols .col ul { list-style: none; margin: 0; padding: 0; }
.footer-container .footer .footer-cols .col ul li { font-weight: 300; }
.footer-container .footer .footer-cols .col .social   { padding-top: 15px; }col col-logo
.footer-container .footer .footer-cols .col .social img   { display: inline-block; vertical-align: top; padding-right: 5px; }
.footer-container .footer .footer-cols .col a:hover     { text-decoration: none; }

.footer-container .footer .footer-cols .col a { }
.footer-container .footer .footer-cols .col a:hover { }
.footer-container .footer .footer-cols .col p { line-height: 30px }
.footer-container .footer .footer-cols .col p strong  { color: $primary; }
.footer-container .footer .footer-cols .col p a { }
.footer-container .footer .footer-cols .col p a:hover { }

.footer-container .footer .footer-cols .col img { }

.social     {  }
.social .social-link    { background: $primary; color: #fff; display: inline-block; vertical-align: top; width: 37px; height: 36px; border-radius: 150px; text-align: center; box-sizing: border-box; padding-top: 3px; margin-right: 5px; }
.social .social-link:hover    { background: $primaryDark; }

/* Footer Newsletter */
.footer-newsletter { margin-top: 25px; }

.footer-newsletter #newsletter-form { position: relative; }
.footer-newsletter #newsletter-form #newsletter-form-email { width: 345px; height: 48px; border: 0; box-sizing: border-box; padding-right: 60px; font-size: 14px; padding-left: 15px; }
.footer-newsletter #newsletter-form #newsletter-form-submit { position: absolute; top: 0; right: -9px; }

.footer-newsletter .success { }
.footer-newsletter .success p { }
#newsletter-form-success    { background: #3d7041; color: #fff; padding: 10px 20px; }

/* Bottom */
.footer-bottom-container { padding-bottom: 20px; }
.footer-bottom-container .footer-bottom { max-width: 1540px; margin: 0 auto; padding: 0; width: 96%; }

/* Copyright */
.footer-bottom-container .footer-bottom .copyright { display: inline-block; vertical-align: top; padding-right: 25px; }
.footer-bottom-container .footer-bottom .address { display: inline-block; vertical-align: top; padding-right: 25px; }
.footer-bottom-container .footer-bottom .company-number { display: inline-block; vertical-align: top; }
.footer-bottom-container .footer-bottom .attrib { float: right; display: inline-block; vertical-align: top; }

@media (max-width: 1370px) {
  /* Footer Bottom */
  .footer-bottom-container .footer-bottom .address    { display: block; }
}

@media (max-width: 1250px) {
  /* Footer Cols */
  .footer-cols .col.col-logo  { width: 20%; }
  /* Newsletter */
  .footer-newsletter #newsletter-form #newsletter-form-email    { width: 100%; }
}

@media (max-width: 950px) {
  /* Footer */
  .footer-cols .col.col-logo  { display: none; }
}

@media (max-width: 768px) {
  /* Footer */
  .footer-container .footer .footer-cols  { display: block; }
  .footer-container .footer .footer-cols .col     { width: 100%; clear: both; padding: 30px 0 0; }
  .footer-container .footer .footer-cols .col.col-newsletter { padding-top: 10px; padding-bottom: 40px; }
  .footer-newsletter #newsletter-form #newsletter-form-submit     { right: 0; }

  .footer-bottom-container .footer-bottom .copyright    { padding-right: 0; }
  .footer-bottom-container .footer-bottom .copyright .detail  { display: block; }
  .footer-bottom-container .footer-bottom .copyright .sep    { display: none; }
  .footer-bottom-container .footer-bottom .copyright a    { margin: 0 5px; }

  /* Centre content */
  .footer-container .footer   { text-align: center; }
  .footer-bottom-container .footer-bottom .address  { text-align: center; display: block; padding-right: 0; }
  .footer-bottom-container .footer-bottom .copyright  { text-align: center; display: block; }
  .footer-bottom-container .footer-bottom .company-number     { text-align: center; display: block; }
  .footer-bottom-container .footer-bottom .attrib   { float: none; text-align: center; display: block; }

  /* Fix */
  .footer-bottom-container .footer-bottom .copyright .detail    { display: none; }
  .footer-bottom-container .footer-bottom .additional-copyright   { display: block !important; text-align: center; }

}